import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useClipboard from "react-use-clipboard";
import { CHAINS } from "../../chains";
import { createClient } from "@layerzerolabs/scan-client";
import { SuccessModalStateStatus } from "../../features/modals/modalsSlice";
import useWallet from "../Wallets/useWallet";
function SuccessTransactionModal() {
  const successModalState = useSelector(
    ({ modals }) => modals.SuccessModalState
  );
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  const [isCopied, setCopied] = useClipboard(successModalState?.txHash);
  const wallet = useSelector(({ account }) => account.wallet);
  const hooks = useWallet(wallet);
  const { useProvider, useChainId } = hooks;
  const chainId = useChainId();
  const checkLayerZeroScan = () => {
    const client = createClient('mainnet');
    client
      .getMessagesBySrcTxHash(successModalState?.txHash)
      .then((result) => {
        dispatch(
          SuccessModalStateStatus({
            status: true,
            txHash: successModalState?.txHash,
            chainId: chainId,
            isMultiChainSwap: true,
            srcChainId: result.messages[0].srcChainId,
            dstChainId: result.messages[0].dstChainId,
            srcUaAddress: result.messages[0].srcUaAddress,
            dstUaAddress: result.messages[0].dstUaAddress,
            srcUaNonce: result.messages[0].srcUaNonce,
          })
        );
      }).catch((err) => {
        dispatch(
          SuccessModalStateStatus({
            status: true,
            txHash: successModalState?.txHash,
            chainId: chainId,
            isMultiChainSwap: true,
          })
        );
      });
  }
  return (
    <>
      <div className='flex flex-col items-center justify-between my-1'>
        <svg
          width='72'
          height='72'
          viewBox='0 0 72 72'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='72' height='72' fill='#17A080' fillOpacity='0.2' />
          <g clipPath='url(#clip0_2122_21520)'>
            <path
              d='M31.3333 43.4015L52.7813 21.9512L56.083 25.2505L31.3333 50.0002L16.484 35.1508L19.7833 31.8515L31.3333 43.4015Z'
              fill='#17A080'
            />
          </g>
          <defs>
            <clipPath id='clip0_2122_21520'>
              <rect
                width='56'
                height='56'
                fill='white'
                transform='translate(8 8)'
              />
            </clipPath>
          </defs>
        </svg>
        {successModalState?.isMultiChainSwap ? (
          <span
            className={`${themeMode === "light" ? "text-[#22223d]" : "text-white"
              } my-3 text-center font-outfit text-[18px] font-medium px-5`}
          >
            Transaction has been submitted to the blockchain It may take up to{" "}
            <span className='text-[#6224FB] font-bold text-[20px]'>20 min</span>{" "}
            to receive funds
          </span>
        ) : (
          <span className='text-[#4FC37E] my-3 text-center font-outfit text-[20px]'>
            The transaction has been completed successfully.
          </span>
        )}

        <div className='my-3 bg-[#ECEAF4] py-3 px-3 w-full flex justify-between items-center'>
          <div className="w-[90%]">
            <span className='break-all font-outfit text-[14px] md:text-[16px]'>
              Txn Hash: {successModalState?.txHash}
            </span>
          </div>
          <div className="w-[10%] flex justify-end">
            {isCopied ? (
              <svg
                width='20'
                height='20'
                viewBox='0 0 41 29'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.3333 22.4015L36.7813 0.951172L40.083 4.25051L15.3333 29.0002L0.484009 14.1508L3.78334 10.8515L15.3333 22.4015Z'
                  fill='#17A080'
                />
              </svg>
            ) : (
              <svg
                width='20'
                height='20'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='cursor-pointer'
                onClick={() => setCopied()}
              >
                <g clipPath='url(#clip0_2122_21584)'>
                  <path
                    d='M4.66667 3.99967V1.99967C4.66667 1.82286 4.7369 1.65329 4.86193 1.52827C4.98695 1.40325 5.15652 1.33301 5.33333 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V11.333C14 11.5098 13.9298 11.6794 13.8047 11.8044C13.6797 11.9294 13.5101 11.9997 13.3333 11.9997H11.3333V13.9997C11.3333 14.3677 11.0333 14.6663 10.662 14.6663H2.67133C2.58342 14.6669 2.49626 14.65 2.41488 14.6168C2.3335 14.5835 2.25949 14.5345 2.19711 14.4725C2.13472 14.4106 2.0852 14.3369 2.05137 14.2558C2.01754 14.1746 2.00009 14.0876 2 13.9997L2.002 4.66634C2.002 4.29834 2.302 3.99967 2.67333 3.99967H4.66667ZM3.33533 5.33301L3.33333 13.333H10V5.33301H3.33533ZM6 3.99967H11.3333V10.6663H12.6667V2.66634H6V3.99967Z'
                    fill='#525168'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2122_21584'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        {successModalState?.isMultiChainSwap &&
          <>
            {successModalState?.srcChainId && successModalState?.srcUaAddress && successModalState?.dstChainId && successModalState?.dstUaAddress && successModalState?.srcUaNonce ?
              <div className='my-3 bg-[#ECEAF4] py-3 px-3 w-full flex justify-between items-center'>
                <div className="w-[90%]">
                  <span className='break-all font-outfit text-[14px] md:text-[16px]'>
                    View the transaction on layerzeroscan
                  </span>
                </div>
                <div className="w-[10%] flex justify-end" onClick={() => window.open(`https://layerzeroscan.com/${successModalState.srcChainId}/address/${successModalState.srcUaAddress}/message/${successModalState.dstChainId}/address/${successModalState.dstUaAddress}/nonce/${successModalState.srcUaNonce}`, '_blank')}>
                  <svg width="16" height="16" viewBox="0 0 18 18" className='cursor-pointer' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0V2H2V16H16V11H18V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H7ZM14.586 2H10V0H18V8H16V3.414L9 10.414L7.586 9L14.586 2Z" fill="#525168" />
                  </svg>
                </div>
              </div>
              :
              <div className='my-3 bg-[#ECEAF4] py-3 px-3 w-full flex justify-between items-center'>
                <div className="w-[90%]">
                  <span className='break-all font-outfit text-[14px] md:text-[16px]'>
                    Transaction not indexed on layerzeroscan, click to recheck
                  </span>
                </div>
                <div className="w-[10%] flex justify-end" onClick={() => checkLayerZeroScan()}>
                  <svg width="18" height="18" viewBox="0 0 20 20" className='cursor-pointer' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.463 2.43301C5.27756 0.86067 7.59899 -0.00333986 10 9.70266e-06C15.523 9.70266e-06 20 4.47701 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C18.0001 8.43163 17.5392 6.89781 16.6747 5.58927C15.8101 4.28072 14.5799 3.25517 13.1372 2.64013C11.6944 2.0251 10.1027 1.84771 8.55996 2.13003C7.0172 2.41234 5.59145 3.14191 4.46 4.22801L3.463 2.43301ZM16.537 17.567C14.7224 19.1393 12.401 20.0034 10 20C4.477 20 0 15.523 0 10C0 7.86401 0.67 5.88401 1.81 4.26001L5 10H2C1.99987 11.5684 2.46075 13.1022 3.32534 14.4108C4.18992 15.7193 5.42007 16.7449 6.86282 17.3599C8.30557 17.9749 9.89729 18.1523 11.44 17.87C12.9828 17.5877 14.4085 16.8581 15.54 15.772L16.537 17.567Z" fill="#525168" />
                  </svg>
                </div>
              </div>
            }
          </>}

        <div className='my-3 bg-[#ECEAF4] py-3 px-3 w-full flex justify-between items-center'>
          <div className="w-[90%]">
            <span className='break-all font-outfit text-[14px] md:text-[16px]'>
              View the transaction on {CHAINS[successModalState?.chainId]?.name}
            </span>
          </div>
          <div className="w-[10%] flex justify-end" onClick={() => window.open(`${CHAINS[successModalState?.chainId].blockExplorerUrls[0]}/tx/${successModalState?.txHash}`, '_blank')}>
            <svg width="16" height="16" viewBox="0 0 18 18" className='cursor-pointer' fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 0V2H2V16H16V11H18V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H7ZM14.586 2H10V0H18V8H16V3.414L9 10.414L7.586 9L14.586 2Z" fill="#525168" />
            </svg>
          </div>
        </div>
        {successModalState?.isMultiChainSwap && (
          <span className='bg-[#f0b90b33] my-3 text-center font-outfit py-3 px-3'>
            If the transaction fails at the destination chain you will receive
            an equivalent stable coin on the destination.
          </span>
        )}
        <a
          className={`${themeMode === "light"
            ? "text-[#6224FB] border-[#6224FB]"
            : "text-white border-white"
            } py-4 px-2 border-solid border-[1px] w-full text-center font-clash font-medium text-[16px] my-3 rounded-[12px]`}
          target='_blank'
          href={`https://t.me/akka_finance`}
        >
          Contact with support
        </a>
      </div>
    </>
  );
}

export default SuccessTransactionModal;
