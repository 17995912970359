import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

function Token({ isVolumeSplitted, exchange, logoImage, symbol }) {
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <div
      className={`z-20 ${
        themeMode === "light" ? "bg-[#F5F5FA]" : "bg-[#22223D]"
      } flex flex-col p-3 h-[auto] ${
        isMobile ? "mx-0 w-[70px]" : "mx-2 w-[130px] rounded-[12px]"
      }`}
    >
      <div
        className={`flex items-center justify-start ${
          themeMode === "light" ? "text-[#22223D]" : "text-white"
        } mb-3`}
      >
        {!isMobile && (
          <div className='mr-2'>
            <img
              src={logoImage}
              alt=''
              width={"25px"}
              className='rounded-[50%]'
            />
          </div>
        )}

        <div>
          <span>{symbol}</span>
        </div>
      </div>
      {!isMobile && isVolumeSplitted && (
        <div
          className={`${
            themeMode === "light" ? "bg-[#FFFFFF]" : "bg-[#18172e80]"
          } px-2 w-full rounded-[12px]`}
        >
          <div
            className={`flex justify-between items-center w-full ${
              themeMode === "light" ? "text-[#525168]" : "text-[#B9B5D3]"
            } my-2`}
          >
            <span>{exchange}</span>
            {/* <span>100%</span> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Token;
