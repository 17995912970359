import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swapIcon from "../../../assets/img/arrows.png";
import darkSwapIcon from "../../../assets/img/darkarrows.png";
import {
  changeAmount,
  changeFromChain,
  changeFromToken,
  changeToChain,
  changeToToken,
} from "../../../features/route/routeSlice";
function SwitchBox() {
  const dispatch = useDispatch();
  const fromToken = useSelector(({ route }) => route.fromToken);
  const toToken = useSelector(({ route }) => route.toToken);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const toChain = useSelector(({ route }) => route.toChain);
  const amount = useSelector(({ route }) => route.amount);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  const themeMode = useSelector(({ theme }) => theme.value);
  const swapBoxes = () => {
    dispatch(changeFromChain(toChain));
    dispatch(changeToChain(fromChain));
    dispatch(changeFromToken(toToken));
    dispatch(changeToToken(fromToken));
    dispatch(
      changeAmount(
        responseData?.return_amount
          ? (
              Math.round(responseData.return_amount * 100000) / 100000
            ).toString()
          : ""
      )
    );
  };
  return (
    <div
      className={`rounded-[50%] w-[36px] mt-[14px] p-2 cursor-pointer border-[1px] ${
        themeMode === "light"
          ? "bg-[#F5F5FA] border-[#ffffff]/[0.01] hover:bg-[#ffffff]/[0.2]"
          : "bg-[#22223D] border-[#000000]/[0.01] hover:bg-[#000000]/[0.2]"
      }`}
      onClick={() => swapBoxes()}
    >
      <img src={themeMode === "light" ? darkSwapIcon : swapIcon} alt='' />
    </div>
  );
}

export default SwitchBox;
