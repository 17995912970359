import { wait } from "@testing-library/user-event/dist/utils";
import { Web3ReactHooks } from "@web3-react/core";
import { AddEthereumChainParameter } from "@web3-react/types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { metaMask } from "../connectors/metaMask";
import { bitkeep } from "../connectors/bitkeep";
import { safepal } from "../connectors/safepal";
import { mathwallet } from "../connectors/mathwallet";
import { walletConnect } from "../connectors/walletConnect";
import { walletConnectV2 } from "../connectors/walletConnectV2";
import { coinbaseWallet } from "../connectors/coinbaseWallet";
import {
  changeAddress,
  changeWallet,
  WalletName,
} from "../features/account/accountSlice";
import { changeModalStatus } from "../features/modals/modalsSlice";
import { changeFromChain, changeToChain } from "../features/route/routeSlice";
import { isMobile } from "react-device-detect";
interface useAuthReturn {
  login: (
    desiredChainIdOrChainParameters?: number | AddEthereumChainParameter,
    chainId?: number,
    walletName?: WalletName
  ) => Promise<void>;
}

const useAuth = ({ useAccount, useChainId }: Web3ReactHooks): useAuthReturn => {
  const dispatch = useDispatch();
  const account = useAccount();
  const walletChainId = useChainId();
  const appChainId = useSelector(({ route }) => route.fromChain);

  const login = useCallback(
    async (
      desiredChainIdOrChainParameters: number | AddEthereumChainParameter,
      chainId: number,
      walletName: WalletName
    ) => {
      if (walletName === "metamask") {
        if (window.ethereum) {
          await metaMask
            .activate(desiredChainIdOrChainParameters)
            .then(() => {
              dispatch(changeModalStatus(false));
              dispatch(changeFromChain(chainId));
              dispatch(changeWallet(walletName));
              localStorage.setItem("wallet", walletName);
              dispatch(changeAddress(account));
            })
            .catch((err) => {
              console.debug(err);
            });
          await wait(500);
          await metaMask.connectEagerly().catch(() => {
            console.debug("Failed to connect eagerly to metamask");
          });
        }
        else if (isMobile) {
          window.open('https://metamask.app.link/dapp/app.akka.finance/')
        }
        else {
          window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en-US')
        }
      }
      if (walletName === "bitkeep") {
        if (window.bitkeep) {
          await bitkeep
            .activate(desiredChainIdOrChainParameters)
            .then(() => {
              dispatch(changeModalStatus(false));
              dispatch(changeFromChain(chainId));
              dispatch(changeWallet(walletName));
              localStorage.setItem("wallet", walletName);
              dispatch(changeAddress(account));
            })
            .catch((err) => {
              console.debug(err);
            });
          await wait(500);
          await bitkeep.connectEagerly().catch(() => {
            console.debug("Failed to connect eagerly to bitkeep");
          });
        }
        else if (isMobile) {
          window.open('https://bkcode.vip/?action=dapp&url=https://app.akka.finance/')
        }
        else {
          window.open('https://bitkeep.com/en/download?type=2');
        }

      }
      if (walletName === "safepal") {
        if (window.safepalProvider) {
          await safepal
            .activate(desiredChainIdOrChainParameters)
            .then(() => {
              dispatch(changeModalStatus(false));
              dispatch(changeFromChain(chainId));
              dispatch(changeWallet(walletName));
              localStorage.setItem("wallet", walletName);
              dispatch(changeAddress(account));
            })
            .catch((err) => {
              console.debug(err);
            });
          await wait(500);
          await safepal.connectEagerly().catch(() => {
            console.debug("Failed to connect eagerly to safepal");
          });
        }
        // else if (isMobile) {
        //   window.open('https://bkcode.vip/?action=dapp&url=https://akka-router-interface-git-add-bitkeep-wallet-akka-finance.vercel.app/')
        // }
        else {
          window.open('https://www.safepal.com/download?product=2');
        }

      }
      if (walletName === "mathwallet") {
        if (window.ethereum) {
          await mathwallet
            .activate(desiredChainIdOrChainParameters)
            .then(() => {
              dispatch(changeModalStatus(false));
              dispatch(changeFromChain(chainId));
              dispatch(changeWallet(walletName));
              localStorage.setItem("wallet", walletName);
              dispatch(changeAddress(account));
            })
            .catch((err) => {
              console.debug(err);
            });
          await wait(500);
          await mathwallet.connectEagerly().catch(() => {
            console.debug("Failed to connect eagerly to mathwallet");
          });
        }
        // else if (isMobile) {
        //   window.open('https://bkcode.vip/?action=dapp&url=https://akka-router-interface-git-add-bitkeep-wallet-akka-finance.vercel.app/')
        // }
        else {
          window.open('https://mathwallet.org/en-us/');
        }

      }
      if (walletName === "walletconnect") {
        await walletConnect
          .activate(chainId)
          .then(() => {
            dispatch(changeModalStatus(false));
            dispatch(changeFromChain(chainId));
            dispatch(changeWallet(walletName));
            localStorage.setItem("wallet", walletName);
            dispatch(changeAddress(account));
          })
          .catch((err) => {
            console.debug(err);
          });
        await wait(500);
        await walletConnect.connectEagerly().catch(() => {
          console.debug("Failed to connect eagerly to walletconnect");
        });
      }
      if (walletName === "walletconnectV2") {
        await walletConnectV2
          .activate(chainId)
          .then(() => {
            dispatch(changeModalStatus(false));
            dispatch(changeFromChain(chainId));
            dispatch(changeWallet(walletName));
            localStorage.setItem("wallet", walletName);
            dispatch(changeAddress(account));
          })
          .catch((err) => {
            console.debug(err);
          });
        await wait(500);
        await walletConnect.connectEagerly().catch(() => {
          console.debug("Failed to connect eagerly to walletconnectV2");
        });
      }
      if (walletName === "coinbasewallet") {
        if (window.coinbaseWalletExtension) {
          await coinbaseWallet
            .activate(chainId)
            .then(() => {
              dispatch(changeModalStatus(false));
              dispatch(changeFromChain(chainId));
              dispatch(changeWallet(walletName));
              localStorage.setItem("wallet", walletName);
              dispatch(changeAddress(account));
            })
            .catch((err) => {
              console.debug(err);
            });
          await wait(500);
          await coinbaseWallet.connectEagerly().catch(() => {
            console.debug("Failed to connect eagerly to walletconnect");
          });
        }
        else if (isMobile) {
          window.open('https://go.cb-w.com/dapp?cb_url=https://app.akka.finance/')
        }
        else {
          window.open('https://www.coinbase.com/wallet/downloads');
        }
      }
    },
    [dispatch, appChainId, walletChainId]
  );
  return { login };
};
export default useAuth;
