import type { AddEthereumChainParameter } from "@web3-react/types";

const ETH: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
};
const MATIC: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};
const BNB: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Bnb",
  symbol: "BNB",
  decimals: 18,
};
const FTM: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Ftm",
  symbol: "FTM",
  decimals: 18,
};
const BRISE: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Bitgert",
  symbol: "BRISE",
  decimals: 18,
};
const XDC: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Xdc",
  symbol: "XDC",
  decimals: 18,
};
const WAN: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Wan",
  symbol: "WAN",
  decimals: 18,
};
const TLOS: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Tlos",
  symbol: "TLOS",
  decimals: 18,
};
const OKT: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Okt",
  symbol: "OKT",
  decimals: 18,
};
const CORE: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Core",
  symbol: "CORE",
  decimals: 18,
};
const SHM: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Shardeum",
  symbol: "SHM",
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[];
  name: string;
  blockExplorerUrls?: AddEthereumChainParameter["blockExplorerUrls"];
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  // BSC
  56: {
    urls: ["https://rpc.ankr.com/bsc"].filter((url) => url !== undefined),
    name: "BSC Mainnet",
    nativeCurrency: BNB,
    blockExplorerUrls: ["https://bscscan.com"],
  },
  // Fantom
  250: {
    urls: ["https://rpc.ankr.com/fantom"].filter((url) => url !== undefined),
    name: "Fantom Mainnet",
    nativeCurrency: FTM,
    blockExplorerUrls: ["https://ftmscan.com"],
  },
  // BSC Testnet
  97: {
    urls: ["https://data-seed-prebsc-2-s3.binance.org:8545/"].filter(
      (url) => url !== undefined
    ),
    name: "BSC Testnet",
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  // Fantom Testnet
  4002: {
    urls: ["https://rpc.ankr.com/fantom_testnet"].filter(
      (url) => url !== undefined
    ),
    name: "Fantom Testnet",
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
  },
  // Brise
  32520: {
    urls: ["https://rpc.icecreamswap.com"].filter((url) => url !== undefined),
    name: "Bitgert Mainnet",
    nativeCurrency: BRISE,
    blockExplorerUrls: ["https://brisescan.com"],
  },
  // Xdc
  50: {
    urls: ["https://rpc.xdcrpc.com"].filter((url) => url !== undefined),
    name: "XDC Mainnet",
    nativeCurrency: XDC,
    blockExplorerUrls: ["https://xdc.blocksscan.io"],
  },
  // Wan
  888: {
    urls: ["https://gwan-ssl.wandevs.org:56891"].filter(
      (url) => url !== undefined
    ),
    name: "WAN Mainnet",
    nativeCurrency: WAN,
    blockExplorerUrls: ["https://wan.blocksscan.io"],
  },
  // Eth
  1: {
    urls: ["https://rpc.ankr.com/eth"].filter((url) => url !== undefined),
    name: "Ethereum Mainnet",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://etherscan.io"],
  },
  // Telos
  40: {
    urls: ["https://mainnet.telos.net/evm"].filter((url) => url !== undefined),
    name: "Telos",
    nativeCurrency: TLOS,
    blockExplorerUrls: ["https://www.teloscan.io"],
  },
  // Okc
  66: {
    urls: ["https://exchainrpc.okex.org"].filter((url) => url !== undefined),
    name: "OKXChain Mainnet",
    nativeCurrency: OKT,
    blockExplorerUrls: ["https://www.oklink.com/okc"],
  },
  // Core
  1116: {
    urls: ["https://rpc-core.icecreamswap.com"].filter(
      (url) => url !== undefined
    ),
    name: "Core Mainnet",
    nativeCurrency: CORE,
    blockExplorerUrls: ["https://scan.coredao.org"],
  },
  // Shardeum
  8081: {
    urls: ["https://dapps.shardeum.org"].filter(
      (url) => url !== undefined
    ),
    name: "Shardeum Betanet",
    nativeCurrency: SHM,
    blockExplorerUrls: ["https://explorer-dapps.shardeum.org"],
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
