import axios from "axios";
import React, { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SearchIcon from "../../../assets/img/search-line.png";
import DarkSearchIcon from "../../../assets/img/darksearch-line.png";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import FromToken from "./FromToken";
import FromTokenlistIsLoading from "./FromTokenlistIsLoading";
import { v4 as uuidv4 } from "uuid";
import { TokenListApi } from "../../../config/api/types";
import useGetTokenList from "../../../hooks/useGetTokenList";
import useTokenComparator from "../../../hooks/useTokenComparator";
import useDebounce from "../../../hooks/useDebounce";
import { createFilterToken, useSortedTokensByQuery } from "../../../utils/filtering";
import { useAllTokens } from "../../../hooks/Tokens";
import { useAllTokenBalances, useNativeBalances } from "../../../state/wallet/hooks";
import { ethers } from "ethers";
import useWallet from "../../Wallets/useWallet";
import useChainInfo from "../../../hooks/useChainInfo";

const StyledInput = styled.input<
  HTMLAttributes<HTMLInputElement> & {
    color: string;
  }
>`
  width: 100%;
  height: 48px;
  padding: 15px 15px;
  padding-left: 45px;
  margin-bottom: 30px;
  margin-top: 10px;
  position: relative;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.02);
  border: 1px solid #414141;
  backdrop-filter: blur(30px);
  color: ${({ color }) => color};
  font-size: 20px;

  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-size: 16px;
  }
`;
function FromTokenlist() {
  const themeMode = useSelector(({ theme }) => theme.value);
  const chainId = useSelector(({ route }) => route.fromChain);

  const chainInfo = useChainInfo(chainId)
  const tokenlist = useGetTokenList(chainId)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)
  const allTokens = useAllTokens()

  const filteredTokens: TokenListApi[] = useMemo(() => {
    const filterToken = createFilterToken(debouncedQuery)
    return Object.values(allTokens).filter(filterToken)
  }, [allTokens, debouncedQuery])

  const filteredQueryTokens = useSortedTokensByQuery(filteredTokens, debouncedQuery)

  const tokenComparator = useTokenComparator()

  const filteredSortedTokens: TokenListApi[] = useMemo(() => {
    return [...filteredQueryTokens].sort(tokenComparator)
  }, [filteredQueryTokens, tokenComparator])

  const foundIdx = filteredSortedTokens.findIndex(el => el.contract_addr === ethers.constants.AddressZero)
  filteredSortedTokens.splice(foundIdx, 1)
  filteredSortedTokens.unshift(tokenlist[0])

  const balances = useAllTokenBalances()

  const wallet = useSelector(({ account }) => account.wallet);
  const { useAccount } = useWallet(wallet);
  const account = useAccount();
  const uncheckedAddresses = useMemo(() => (account ? [account] : []), [account])
  const nativeBalances = useNativeBalances(uncheckedAddresses)




  return (
    <>
      {/* Tokens list search box */}
      <div className='relative'>
        <StyledInput
          color={themeMode === "light" ? "#22223D" : "white"}
          className='font-outfit font-[500] text-[16px] rounded-[12px] hover:border-[#ffffff]/[0.2] focus:border-[#D6C1FC]'
          type='text'
          placeholder='Search name or paste address'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <img
          src={themeMode === "light" ? DarkSearchIcon : SearchIcon}
          alt=''
          className='absolute top-[26px] left-[15px] w-[16px]'
        />
      </div>

      {/* Tokens list box */}
      <div
        className='flex flex-col w-[100%] overflow-y-scroll h-[500px] scrollbar'
      >
        {!Boolean(filteredSortedTokens?.length) ? (
          // is Loading Component
          <FromTokenlistIsLoading />
        ) : (
          filteredSortedTokens.slice(0, 56).map((token: TokenListApi, index) => {
            return (
              <FromToken account={account} chainInfo={chainInfo} themeMode={themeMode} token={token} balance={token.contract_addr === ethers.constants.AddressZero ? nativeBalances[account] : balances[token.contract_addr]} key={uuidv4()} />
            )
          })
        )}
      </div>
    </>
  );
}

export default FromTokenlist;
