import React from "react";
import { useSelector } from "react-redux";
import FromToValue from "./FromToValue";
import goAheadIcon from "../../../assets/go-ahead.png";
import { isMobile } from "react-device-detect";

interface TokenProps {
  isAlone: boolean
}
function FromToken({ isAlone }: TokenProps) {
  const fromToken = useSelector(({ route }) => route.fromToken);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  return (
    isAlone || isMobile ?
      <div id='from-token' className='w-[100%] flex justify-start'>
        <FromToValue
          logoImage={fromToken.adress}
          amount={responseData.input_amount}
          symbol={fromToken.symbol}
          chain='from'
        />
      </div>
      :
      <div id='from-token' className='w-[100%] flex justify-start relative'>
        <div className='absolute left-[71px] top-[66px] rotate-90'>
          <img src={goAheadIcon} alt='' width={"8px"} />
        </div>
        <svg
          className='absolute left-[65px] top-[51px]'
          width='11'
          height={`50`}
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <line
            strokeDasharray='1, 3'
            x1='10'
            y1='1'
            x2='10'
            y2={`50`}
          ></line>
        </svg>
        <FromToValue
          logoImage={fromToken.adress}
          amount={responseData.input_amount}
          symbol={fromToken.symbol}
          chain='from'
        />
      </div>
  );
}

export default FromToken;
