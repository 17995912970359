import { FC, MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hooks, metaMask } from "../../connectors/metaMask";
import metaMaskIcon from "../../assets/img/wallets/metamask.png";
import { useDispatch } from "react-redux";

const MetaMaskCard: FC<{
  handleClick: (e: MouseEvent<HTMLElement>) => void;
}> = ({ handleClick }) => {
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <div
      className={`w-[49%] flex flex-col items-center justify-between rounded-[12px] px-[12px] py-[15px] border-[5px] mb-2 cursor-pointer hover:border-[5px] hover:border-[#814AFB] ${
        themeMode === "light"
          ? "border-[#F5F5FA] bg-[#F5F5FA]"
          : "border-[#22223D] bg-[#22223D]"
      }`}
      onClick={handleClick}
    >
      <div>
        <img src={metaMaskIcon} alt='' className='w-[32px] mb-2' />
      </div>
      <h6
        className={`font-medium text-[16px] ${
          themeMode === "light" ? "#22223D" : "text-white"
        }`}
      >
        MetaMask
      </h6>
    </div>
  );
};

export default MetaMaskCard;
