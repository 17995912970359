import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import FromToValue from "./FromToValue";
import goAheadIcon from "../../../assets/go-ahead.png";

interface TokenProps {
  isAlone: boolean
}
function ToToken({ isAlone }: TokenProps) {
  const toToken = useSelector(({ route }) => route.toToken);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  return (
    isAlone || isMobile ?
      <div
        id='to-token'
        className={`w-[100%] flex justify-end ${isMobile ? "mt-[15px]" : "mt-[50px]"
          }`}
      >
        <FromToValue
          logoImage={toToken.adress}
          amount={responseData.return_amount}
          symbol={toToken.symbol}
          chain="to"
        />
      </div>
      :
      <div
        id='to-token'
        className={`w-[100%] flex justify-end ${isMobile ? "mt-[15px]" : "mt-[50px] relative"
          }`}
      >
        <div className='absolute right-[72px] bottom-[70px] rotate-90'>
          <img src={goAheadIcon} alt='' width={"8px"} />
        </div>
        <svg
          className='absolute right-[75px] bottom-[51px]'
          width='11'
          height={`50`}
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <line
            strokeDasharray='1, 3'
            x1='10'
            y1='1'
            x2='10'
            y2={`50`}
          ></line>
        </svg>
        <FromToValue
          logoImage={toToken.adress}
          amount={responseData.return_amount}
          symbol={toToken.symbol}
          chain="to"
        />
      </div>
  )
}

export default ToToken;
