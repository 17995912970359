import { Web3ReactHooks, Web3ReactProvider } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Network } from "@web3-react/network";
import { WalletConnect } from "@web3-react/walletconnect";
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import { hooks as bitkeepHooks, bitkeep } from "./connectors/bitkeep";
import { hooks as safepalHooks, safepal } from "./connectors/safepal";
import { hooks as mathwalletHooks, mathwallet } from "./connectors/mathwallet";
import { hooks as networkHooks, network } from "./connectors/network";
import {
  hooks as walletConnectHooks,
  walletConnect,
} from "./connectors/walletConnect";
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnectV2'
import { coinbaseWallet, hooks as coinbaseWalletHooks } from './connectors/coinbaseWallet'
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { usePollBlockNumber } from "./state/block/hooks";
import Updater from "./state/transactions/updater";
import MulticallUpdater from './state/multicall/updater'
import ReactGA from "react-ga";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import store from "./state";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BitKeep } from "@akkafinance/web3-react-bitkeep";
import { SafePal } from "@akkafinance/web3-react-safepal";
import { MathWallet } from "@akkafinance/web3-react-mathwallet";

const queryClient = new QueryClient();
const connectors: [MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet | Network | BitKeep | SafePal | MathWallet, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [network, networkHooks],
  [bitkeep, bitkeepHooks],
  [safepal, safepalHooks],
  [mathwallet, mathwalletHooks],
]

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function GlobalHooks() {
  usePollBlockNumber();
  return null;
}
ReactGA.initialize("UA-245771026-1");
ReactGA.pageview(window.location.pathname + window.location.search);
root.render(
  <>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Web3ReactProvider connectors={connectors}>
            <ToastContainer
              position={"top-center"}
              autoClose={3000}
              hideProgressBar={false}
              closeOnClick={true}
              pauseOnHover={true}
              draggable={true}
              theme={localStorage.getItem("theme") === "light" ? "light" : "dark"} />
            <GlobalHooks />
            <Updater />
            <MulticallUpdater />
            <App />
          </Web3ReactProvider>
        </Router>
      </QueryClientProvider>
    </Provider>
  </>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
