import { ChainId } from "../config/constants/types";
import bnblightIcon from "../assets/img/chains/binance-light.svg";
import briseIcon from "../assets/img/chains/brise.png";
import xdcIcon from "../assets/img/chains/xdc-icon.png";
import fantomIcon from "../assets/img/chains/fantom.svg";
import wanIcon from "../assets/img/chains/wanchain.png";
import telosIcon from "../assets/img/chains/teloschain.png";
import okcIcon from "../assets/img/chains/okx.svg";
import coreIcon from "../assets/img/chains/core.svg";
import ethIcon from "../assets/img/chains/ethereum.png";
import shardeumIcon from "../assets/img/chains/shardeum.jpeg";

export interface ChainInfoType {
  name: string;
  icon: string;
  apiKey: string;
  id: ChainId;
}

function useChainInfo(chainId: ChainId): ChainInfoType {
  switch (chainId) {
    case ChainId.BSC:
      return {
        name: "BNB",
        icon: bnblightIcon,
        apiKey: "bsc",
        id: ChainId.BSC,
      };
    case ChainId.FTM:
      return {
        name: "Fantom",
        icon: fantomIcon,
        apiKey: "fantom",
        id: ChainId.FTM,
      };
    case ChainId.BRISE:
      return {
        name: "Bitgert",
        icon: briseIcon,
        apiKey: "bitgert",
        id: ChainId.BRISE,
      };
    case ChainId.XDC:
      return { name: "XDC", icon: xdcIcon, apiKey: "xdc", id: ChainId.XDC };
    case ChainId.WAN:
      return {
        name: "Wan",
        icon: wanIcon,
        apiKey: "wanchain",
        id: ChainId.WAN,
      };
    case ChainId.ETH:
      return {
        name: "Eth",
        icon: ethIcon,
        apiKey: "ethereum",
        id: ChainId.ETH,
      };
    case ChainId.TELOS:
      return {
        name: "Telos",
        icon: telosIcon,
        apiKey: "teloschain",
        id: ChainId.TELOS,
      };
    case ChainId.OKC:
      return {
        name: "OKT Chain",
        icon: okcIcon,
        apiKey: "okc",
        id: ChainId.OKC,
      };
    case ChainId.SHARDEUM:
      return {
        name: "Shardeum Betanet",
        icon: shardeumIcon,
        apiKey: "shardeum",
        id: ChainId.SHARDEUM,
      };
    case ChainId.CORE:
      return {
        name: "Core",
        icon: coreIcon,
        apiKey: "core",
        id: ChainId.CORE,
      };
  }
}

export default useChainInfo;
