import { useMemo } from "react"
import { useSelector } from "react-redux";
import useWallet from "../components/Wallets/useWallet";
import { TokenListApi } from "../config/api/types"
import useGetTokenList from "./useGetTokenList";

const mapWithoutUrls = (tokenMap: TokenListApi[]) =>
    Object.keys(tokenMap || {}).reduce<{ [address: string]: TokenListApi }>((newMap) => {
        return newMap
    }, {})

export function useAllTokens(): { [address: string]: TokenListApi } {
    const chainId = useSelector(({ route }) => route.fromChain);
    const tokens: TokenListApi[] = useGetTokenList(chainId)

    return useMemo(() => {
        return (
            tokens
                // reduce into all ALL_TOKENS filtered by the current chain
                .reduce<{ [address: string]: TokenListApi }>(
                    (tokens, token) => {
                        tokens[token.contract_addr] = token
                        return tokens
                    },
                    mapWithoutUrls(tokens),
                )
        )
    }, [tokens])
}