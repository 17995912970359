// eslint-disable-next-line camelcase
import { useSelector } from 'react-redux'
import useSWR, { useSWRConfig, unstable_serialize } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useWallet from '../../components/Wallets/useWallet'
import { FAST_INTERVAL, SLOW_INTERVAL } from '../../config/constants'

const REFRESH_BLOCK_INTERVAL = 6000

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig()
  const wallet = useSelector(({ account }) => account.wallet);
  const {
    useChainId,
    useProvider
  } = useWallet(wallet);
  const chainId = useChainId();
  const provider = useProvider()

  const { data } = useSWR(
    chainId && ['blockNumberFetcher', chainId],
    async () => {
      const blockNumber = await provider.getBlockNumber()
      mutate(['blockNumber', chainId], blockNumber)
      if (!cache.get(unstable_serialize(['initialBlockNumber', chainId]))) {
        mutate(['initialBlockNumber', chainId], blockNumber)
      }
      return blockNumber
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    },
  )

  useSWR(
    chainId && [FAST_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  useSWR(
    chainId && [SLOW_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )
}

export const useCurrentBlock = (): number => {
  const wallet = useSelector(({ account }) => account.wallet);
  const {
    useChainId,
  } = useWallet(wallet);
  const chainId = useChainId();
  const { data: currentBlock = 0 } = useSWRImmutable(['blockNumber', chainId])
  return currentBlock
}

export const useChainCurrentBlock = (chainId: number): number => {
  const wallet = useSelector(({ account }) => account.wallet);
  const {
    useChainId,
    useProvider
  } = useWallet(wallet);
  const activeChainId = useChainId();
  const provider = useProvider()
  const { data: currentBlock = 0 } = useSWR(
    chainId ? (activeChainId === chainId ? ['blockNumber', chainId] : ['chainBlockNumber', chainId]) : null,
    activeChainId !== chainId
      ? async () => {
        const blockNumber = await provider.getBlockNumber()
        return blockNumber
      }
      : undefined,
    activeChainId !== chainId
      ? {
        refreshInterval: REFRESH_BLOCK_INTERVAL,
      }
      : undefined,
  )
  return currentBlock
}

export const useInitialBlock = (): number => {
  const wallet = useSelector(({ account }) => account.wallet);
  const {
    useChainId,
  } = useWallet(wallet);
  const chainId = useChainId();
  const { data: initialBlock = 0 } = useSWRImmutable(['initialBlockNumber', chainId])
  return initialBlock
}
