import React from "react";
import refreshImage from "../../../assets/img/refreshicon.png";
import darkRefreshImage from "../../../assets/img/darkrefresh.png";
import { useDispatch } from "react-redux";
import { changeCounter } from "../../../features/route/routeSlice";
import { useSelector } from "react-redux";

function FromRefresh() {
  const dispatch = useDispatch();
  const counter = useSelector(({ route }) => route.counter);
  const refresher = () => {
    dispatch(changeCounter(counter + 1));
  };
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <>
      <div
        onClick={refresher}
        className={`p-2 rounded-md cursor-pointer ${
          themeMode === "light" ? "hover:bg-[#eeeeee]" : "hover:bg-[#1B1A2E]"
        }`}
      >
        <div>
          <img
            // data-tip
            // data-for="settingbutton"
            src={themeMode === "light" ? darkRefreshImage : refreshImage}
            alt=''
            className='w-[16px]'
          />
        </div>
      </div>
    </>
  );
}

export default FromRefresh;
