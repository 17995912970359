import axios, { AxiosResponse } from "axios";
import { ChainId, NetworkName } from "../constants/types";
import { HttpMethod, RequestPath, TokenListApi } from "./types";
const instance = axios.create({
  baseURL: "https://api.akka.foundation/",
});
const api = <R>(
  method: HttpMethod,
  path: RequestPath,
  config?: any
): Promise<AxiosResponse<R, any>> => {
  switch (method) {
    case "GET":
      return instance.get(path, config);
    case "POST":
      instance.post(path).then((res) => res);
      break;
  }
};
const getTokenlist = async (chain: NetworkName, limit: number = 10000) =>
  await api<TokenListApi[]>(
    "GET",
    `/tokens?chain=${chain.toLocaleLowerCase()}&limit=${limit}`
  );
const getRoute = async (
  fromChain: string,
  toChain: string,
  fromTokenAddress: string,
  toTokenAddress: string,
  amount: string,
  slippageTolerance: number,
  controller: AbortController,
) =>
  await api<any>(
    "GET",
    `/route?token0=${fromTokenAddress}&chain0=${fromChain}&token1=${toTokenAddress}&chain1=${toChain}&amount=${amount}&slipage=${slippageTolerance}&use_split=true`,
    {
      signal: controller.signal,
    }
  );
const getSwap = async (
  fromChain: string,
  toChain: string,
  fromTokenAddress: string,
  toTokenAddress: string,
  amount: string,
  slippageTolerance: number,
  account: string,
  controller: AbortController,
  dstBridgeFee: number,
) =>
  await api<any>(
    "GET",
    `/swap?token0=${fromTokenAddress}&chain0=${fromChain}&token1=${toTokenAddress}&chain1=${toChain}&amount=${amount}&slipage=${slippageTolerance}&use_split=true&user_wallet_addr=${account}&dst_bridgefee=${dstBridgeFee}`,
    {
      signal: controller.signal,
    }
  );
// const getPriceOfToken = async (symbol: string, fiat: string = "USD") =>
//   await api(
//     "GET",
//     `https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=${symbol}&convert=${fiat}`,
//     {
//       headers: {
//         "X-CMC_PRO_API_KEY": "e8463497-8b56-49ba-80f3-91c455569f15",
//       },
//     }
//   );

export { getTokenlist, getSwap, getRoute };
