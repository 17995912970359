import React from "react";
import lightlogoPic from "../../assets/lightlogo2.png";
import darklogoPic from "../../assets/akka-colorfull1.svg";
import TwitterIcon from "../../assets/img/socials/twitter.png";
import TelegramIcon from "../../assets/img/socials/telegram.png";
import { useSelector } from "react-redux";

function Footer() {
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <footer
      className={`w-[100%] flex justify-center items-center ${
        themeMode === "light"
          ? "bg-[#F5F5FA] md:bg-[#F5F5FA]"
          : "bg-[#22223d] md:bg-[#22223d]"
      }  py-[15px] px-[50px] md:min-h-[18vh]`}
    >
      <div className='flex flex-col'>
        <img
          src={themeMode === "light" ? darklogoPic : lightlogoPic}
          alt='Logo'
          className='h-[50px] my-2 z-50'
        />
        <div className='flex justify-evenly py-5 mt-2 items-center z-50'>
          <a href='https://twitter.com/akka_finance' target='_blank'>
            <img src={TwitterIcon} alt='' className='w-[20px]' />
          </a>

          <a href='https://t.me/akka_finance' target='_blank'>
            <img src={TelegramIcon} alt='' className='w-[20px]' />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
