import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { CHAINS } from '../chains'

const chains = Object.keys(CHAINS).map(Number)

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
    (actions) =>
        new WalletConnectV2({
            actions,
            options: {
                projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
                chains: chains,
                ...chains,
                showQrModal: true,
            },
        })
)