import React, { FC, HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ArrowDownFont from "../../../assets/arrow-down-sign-to-navigate.png";
import DarkArrowDownFont from "../../../assets/darkarrow-down-sign-to-navigate.png";
import { ToTokenlistStatus } from "../../../features/modals/modalsSlice";
import tokenImage from "../../../assets/img/creation.png";
import useChainInfo from "../../../hooks/useChainInfo";
import { isMobile } from "react-device-detect";
const StyledButton = styled.div<
  HTMLAttributes<HTMLElement> & {
    backgroundColor: string;
    borderColor: string;
  }
>`
width: ${isMobile ? "50%" : "33.33%"};
height: 48px;
display: flex;
justify-content: center;
align-items: center;
padding: 15px 14.5px;
text-decoration: none;
background: rgba(255, 255, 255, 0.02);
border: 1px solid ${({ borderColor }) => borderColor};
border-radius: 12px;
font-size: 14px;
cursor: pointer;
@media (max-width: 768px) {
  // border-right: 1px solid #2F2E3C;
}
`;
const ToChangeChainButton: FC<{
  imageSrc: string;
  coinName: string;
  chain: string;
}> = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  const toToken = useSelector(({ route }) => route.toToken);
  const chainId = useSelector(({ route }) => route.toChain);
  const chainInfo = useChainInfo(chainId);
  return (
    <StyledButton
      className='hover:bg-[#ffffff]/[0.2]'
      backgroundColor={
        themeMode === "light"
          ? "rgba(255, 255, 255, 0.02)"
          : "rgba(255, 255, 255, 0.02)"
      }
      borderColor={themeMode === "light" ? "#E1DEF2" : "#2f2e3c"}
      onClick={() => dispatch(ToTokenlistStatus(true))}
    >
      <div className='w-[25%] max-w-[20px]'>
        <img
          src={
            toToken.adress
              ? `https://cdn.akka.finance/images/${chainInfo.apiKey}/${toToken.adress
              }.png`
              : tokenImage
          }
          alt=''
          className='w-[20px] rounded-[50%]'
        />
      </div>
      <div
        className={`mx-2 w-[65%] text-center font-outfit ${themeMode === "light" ? "text-[#22223D]" : "text-[#eeeeee]"
          } text-[14px]`}
      >
        <h2>{toToken.symbol === "" ? "Select Token" : toToken.symbol}</h2>
      </div>
      <div className='w-[10%]'>
        <img
          src={themeMode === "light" ? DarkArrowDownFont : ArrowDownFont}
          alt=''
          className='w-[10px]'
        />
      </div>
    </StyledButton>
  );
};

export default ToChangeChainButton;
