import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledInput = styled.input<{
  borderColor: string;
  color: string;
}>`
position: relative;
text-align: left;
text-overflow: ellipsis;
font-weight: 400;
padding: 10px;
display: block;
color: ${({ color }) => color};
background: rgba(255, 255, 255, 0.02);
border: 1px solid ${({ borderColor }) => borderColor};
// border-left: 0;
outline: none;
width: ${isMobile ? "100%" : "33.33%"};
height: 48px;
min-height: 48px;
white-space: nowrap;
overflow: hidden;
border-radius: 12px;
appearance: textfield;
@media (max-width: 768px) {
  margin-top: 10px;
  margin-left: 0px;
  // border-left: 1px solid #2F2E3C;
}
&:focus {
  outline: none;
}
::placeholder,
::-webkit-input-placeholder {
  font-size: 14px;
}
:-ms-input-placeholder {
  font-size: 14px;
}
`;
function ToInput() {
  const themeMode = useSelector(({ theme }) => theme.value);
  const recieve = useSelector(({ route }) => route.recieve);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  return (
    <StyledInput
      borderColor={themeMode === "light" ? "#E1DEF2" : "#2f2e3c"}
      className='font-outfit font-[500] text-[18px] focus:border-[#D6C1FC]'
      color={themeMode === "light" ? "#22223D" : "white"}
      placeholder='Recieve amount'
      value={
        responseData?.return_amount
          ? Math.round(responseData.return_amount * 100000) / 100000
          : ""
      }
      disabled
    />
  );
}

export default ToInput;
