import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { TokenListApi } from '../config/api/types'
import { useAllTokenBalances } from '../state/wallet/hooks'

// compare two token amounts with highest one coming first
function balanceComparator(balanceA?: BigNumber, balanceB?: BigNumber) {
    if (balanceA && balanceB) {
        return balanceA.gt(balanceB) ? -1 : balanceA.eq(balanceB) ? 0 : 1
    }
    if (balanceA && balanceA.gt('0')) {
        return -1
    }
    if (balanceB && balanceB.gt('0')) {
        return 1
    }
    return 0
}

function getTokenComparator(balances: {
    [tokenAddress: string]: BigNumber | undefined
}): (tokenA: TokenListApi, tokenB: TokenListApi) => number {
    return function sortTokens(tokenA: TokenListApi, tokenB: TokenListApi): number {
        // -1 = a is first
        // 1 = b is first

        // sort by balances
        const balanceA = balances[tokenA.contract_addr]
        const balanceB = balances[tokenB.contract_addr]

        const balanceComp = balanceComparator(balanceA, balanceB)
        if (balanceComp !== 0) return balanceComp
    }
}

function useTokenComparator(): (tokenA: TokenListApi, tokenB: TokenListApi) => number {
    const balances = useAllTokenBalances()    
    const comparator = useMemo(() => getTokenComparator(balances ?? {}), [balances])    
    return useMemo(() => {
        return comparator
    }, [comparator])
}

export default useTokenComparator
