import Modal from "react-modal";
import CloseIcon from "../../assets/img/close.png";
import DarkCloseIcon from "../../assets/img/darkclose.png";
import {
  changeModalStatus,
  SuccessModalStateStatus,
} from "../../features/modals/modalsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ConnectWalletModal from "../Wallets/ConnectWalletModal";
import FromTokenlist from "../Main/From/FromTokenlist";
import FromNetworklist from "../Main/From/FromNetworklist";
import ToTokenlist from "../Main/To/ToTokenlist";
import ToNetworklist from "../Main/To/ToNetworklist";
import FromAdvanceSetting from "../Main/From/FromAdvanceSetting";
import { isMobile } from "react-device-detect";
import SuccessTransactionModal from "./SuccessTransactionModal";
import FailedTransactionModal from "./FailedTransactionModal";
import ApproveModal from "./ApproveModal";

Modal.setAppElement("#root");

function GeneralModal() {
  const dispatch = useDispatch();
  const generalModal = useSelector(
    ({ modals }) => modals.generalModal
  );
  const connectWalletModal = useSelector(
    ({ modals }) => modals.connectWalletModal
  );
  const fromTokenlistModal = useSelector(
    ({ modals }) => modals.fromTokenlistModal
  );
  const fromNetworklistModal = useSelector(
    ({ modals }) => modals.fromNetworklistModal
  );
  const ToTokenlistModal = useSelector(
    ({ modals }) => modals.ToTokenlistModal
  );
  const ToNetworklistModal = useSelector(
    ({ modals }) => modals.ToNetworklistModal
  );
  const fromAdvanceSettingModal = useSelector(
    ({ modals }) => modals.fromAdvanceSettingModal
  );
  const successModalState = useSelector(
    ({ modals }) => modals.SuccessModalState
  );
  const failedModalState = useSelector(
    ({ modals }) => modals.FailedModalState
  );
  const approveModal = useSelector(
    ({ modals }) => modals.approveModal
  );
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <div>
      <Modal
        closeTimeoutMS={200}
        isOpen={generalModal}
        onRequestClose={() => dispatch(changeModalStatus(false))}
        style={{
          overlay: {
            backgroundColor: "rgba(34, 34, 61, 0.7)",
            zIndex: 60,
          },
          content: {
            top: isMobile ? "100%" : "50%",
            left: "50%",
            transform: isMobile
              ? "translate(-50%, -100%)"
              : "translate(-50%, -50%)",
            right: "auto",
            bottom: "auto",
            width: isMobile ? "100%" : "500px",
            minWidth: isMobile ? "100%" : "500px",
            height: "auto",
            maxWidth: "420px",
            maxHeight: isMobile ? "60vh" : "90vh",
            marginRight: "-50%",
            padding: "24px",
            borderRadius: isMobile ? "12px 12px 0 0" : "12px",
            backgroundColor: themeMode === "light" ? "#FFFFFF" : "#171629",
            border: "none",
            boxSizing: "border-box",
          },
        }}
        contentLabel='General Modal'
      >
        <div
          className={`flex justify-between items-center mb-5 pb-4 border-b-[2px] ${themeMode === "light"
            ? "border-[#000000]/[0.1]"
            : "border-[#ffffff1a]"
            } bg-clip-padding border-opacity-25`}
        >
          <div>
            <h4
              className={`font-medium ${themeMode === "light" ? "#22223D" : "text-white"
                }`}
            >
              {/* Check if needs to show Connect wallet modal */}
              {connectWalletModal ? "Connect a Wallet" : ""}
              {/* Check if needs to show from token list modal */}
              {fromTokenlistModal ? "Select a token" : ""}
              {/* Check if needs to show from network list modal */}
              {fromNetworklistModal ? "Select your network" : ""}
              {/* Check if needs to show to token list modal */}
              {ToTokenlistModal ? "Select a token" : ""}
              {/* Check if needs to show to network list modal */}
              {ToNetworklistModal ? "Select your network" : ""}
              {/* Check if needs to show to advance setting modal */}
              {fromAdvanceSettingModal ? "Trade Settings" : ""}
              {/* Check if needs to show to success ttansaction modal */}
              {successModalState.status ? "Done" : ""}
              {/* Check if needs to show to failed ttansaction modal */}
              {failedModalState.status ? "Failed" : ""}
              {/* Show approve modal */}
              {approveModal ? "Unlock Token" : ""}
            </h4>
          </div>
          <div>
            <img
              src={themeMode === "light" ? DarkCloseIcon : CloseIcon}
              alt=''
              onClick={() => dispatch(changeModalStatus(false))}
              className={`cursor-pointer ${themeMode === "light" ? "w-[20px]" : "w-[15px]"
                }`}
            />
          </div>
        </div>
        {/* Check if needs to show Connect wallet modal */}
        {connectWalletModal ? <ConnectWalletModal /> : ""}
        {/* Check if needs to show from token list modal */}
        {fromTokenlistModal ? <FromTokenlist /> : ""}
        {/* Check if needs to show from network list modal */}
        {fromNetworklistModal ? <FromNetworklist /> : ""}
        {/* Check if needs to show to token list modal */}
        {ToTokenlistModal ? <ToTokenlist /> : ""}
        {/* Check if needs to show to network list modal */}
        {ToNetworklistModal ? <ToNetworklist /> : ""}
        {/* Check if needs to show to advance setting modal */}
        {fromAdvanceSettingModal ? <FromAdvanceSetting /> : ""}
        {/* Check if needs to show to success transaction modal */}
        {successModalState.status ? <SuccessTransactionModal /> : ""}
        {/* Check if needs to show to failed transaction modal */}
        {failedModalState.status ? <FailedTransactionModal /> : ""}
        {/* Show approve modal */}
        {approveModal ? <ApproveModal /> : ""}
      </Modal>
    </div>
  );
}
export default GeneralModal;
