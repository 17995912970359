import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAddChainParameters } from '../../../chains';
import { hooks as metamaskhooks } from "../../../connectors/metaMask";
import { hooks as walletconnecthooks } from "../../../connectors/walletConnect";
import { changeModalStatus } from '../../../features/modals/modalsSlice';
import { changeFromChain, changeFromToken, changeShowRoute, changeToChain, changeToToken } from '../../../features/route/routeSlice';
import useAuth from '../../../hooks/useAuth';
import useWallet from '../../Wallets/useWallet';
import useChainInfo from '../../../hooks/useChainInfo';
import { ChainId } from '../../../config/constants/types';
import useGetTokenList from '../../../hooks/useGetTokenList';

type FromSelectChainButtonType = {
    chainId: ChainId;
}
function FromSelectChainButton({ chainId }: FromSelectChainButtonType) {
    const dispatch = useDispatch();
    const { useIsActive: metamaskUseIsActive } = metamaskhooks;
    const metamaskIsActive = metamaskUseIsActive();
    const { useIsActive: walletconnectUseIsActive } = walletconnecthooks;
    const walletconnectIsActive = walletconnectUseIsActive();
    const wallet = useSelector(({ account }) => account.wallet);
    const themeMode = useSelector(({ theme }) => theme.value);
    const web3Hooks = useWallet(wallet);
    const { useIsActive } = web3Hooks;
    const isActive = useIsActive();
    const { login } = useAuth(web3Hooks);
    const changeChainId = async (chainid: number) => {
        // dispatch(changeStatus(true));
        await login(getAddChainParameters(chainid), chainid, wallet);
        dispatch(changeFromChain(chainid));
        // dispatch(changeStatus(false));
    };
    const tokenList = useGetTokenList(chainId)
    const chainInfo = useChainInfo(chainId)
    return (
        <li className='w-[50%] flex-1'>
            <div
                onClick={() => {
                    isActive && changeChainId(chainId);
                    dispatch(changeFromChain(chainId));
                    dispatch(changeToChain(chainId));
                    dispatch(changeShowRoute(false));
                    dispatch(changeModalStatus(false));
                    dispatch(
                        changeFromToken({
                            name: tokenList[0].name,
                            adress: tokenList[0].contract_addr,
                            image: `https://cdn.akka.finance/images/${chainInfo.apiKey}/${tokenList[0].contract_addr}.png`,
                            symbol: tokenList[0].symbol,
                            decimals: tokenList[0].decimals,
                        })
                    );
                    dispatch(
                        changeToToken({
                            name: tokenList[0].name,
                            adress: tokenList[0].contract_addr,
                            image: `https://cdn.akka.finance/images/${chainInfo.apiKey}/${tokenList[0].contract_addr}.png`,
                            symbol: tokenList[0].symbol,
                            decimals: tokenList[0].decimals,
                        })
                    );
                }}
                className={`flex flex-col items-center justify-between px-[12px] py-[15px] border-[5px] ${themeMode === "light"
                    ? "border-[#F5F5FA] bg-[#F5F5FA]"
                    : "border-[#22223D] bg-[#22223D]"
                    } mb-2 cursor-pointer hover:border-[5px] hover:border-[#814AFB] rounded-[12px]`}
            >
                <img src={chainInfo.icon} alt='' className='w-[56px] mb-2' />
                <span
                    className={`w-[60px] md:w-[100px] font-medium text-[16px] text-center ${themeMode === "light" ? "text-[#22223D]" : "text-white"
                        }`}
                >
                    {chainInfo.name}
                </span>
            </div>
        </li>
    )
}

export default FromSelectChainButton