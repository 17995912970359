import { useSelector } from "react-redux";
import Bridge from "./Bridge";
import { isMobile } from "react-device-detect";
import ToToken from "./ToToken";
import FromToken from "./FromToken";
import FromChain from "./FromChain";
import ToChain from "./ToChain";
import useChainInfo from "../../../hooks/useChainInfo";

function Route() {
  const showRoute = useSelector(({ route }) => route.showRoute);
  const toChain = useSelector(({ route }) => route.toChain);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const toToken = useSelector(({ route }) => route.toToken);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  const fromChainInfo = useChainInfo(fromChain)
  const toChainInfo = useChainInfo(toChain)

  return (
    <>
      {showRoute ? (
        <div
          className={`${isMobile ? "pt-[30px]" : "pt-[10px]"
            } flex flex-col w-[100%] justify-center items-center mb-[25px] font-outfit font-[400] text-[14px]`}
        >
          <FromToken isAlone={responseData.routes[fromChainInfo.id]?.length !== 0} />
          {responseData.routes[fromChainInfo.id]?.length !== 0 && <FromChain />}
          {fromChain !== toChain &&
            fromToken &&
            toToken &&
            responseData.routes.bridge !== undefined &&
            responseData.routes["bridge"] && <Bridge />}
          {fromChain !== toChain &&
            fromToken &&
            toToken &&
            responseData.routes.bridge !== undefined &&
            responseData.routes[toChainInfo.id].length !== 0 && <ToChain />}
          <ToToken isAlone={fromChain !== toChain &&
            fromToken &&
            toToken &&
            responseData.routes.bridge !== undefined &&
            responseData.routes[toChainInfo.id].length !== 0} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Route;
