import { useMemo } from 'react'
import { useAllTokens } from '../../hooks/Tokens'
import { BigNumber, ethers } from 'ethers'
import { isAddress } from '../../utils'
import useWallet from '../../components/Wallets/useWallet'
import { useSelector } from 'react-redux'
import { useMultipleContractSingleData, useSingleContractMultipleData } from '../multicall/hooks'
import ERC20_INTERFACE from '../../config/abi/erc20'
import { TokenListApi } from '../../config/api/types'
import { useMulticallContract } from '../../hooks/useContract'
import { orderBy } from 'lodash'

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (TokenListApi | undefined)[],
): [{ [tokenAddress: string]: BigNumber | undefined }, boolean] {

  const validatedTokens: TokenListApi[] = useMemo(
    () => tokens?.filter((t?: TokenListApi): t is TokenListApi => isAddress(t?.contract_addr) !== false) ?? [],
    [tokens],
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.contract_addr), [validatedTokens])

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20_INTERFACE,
    'balanceOf',
    useMemo(() => [address], [address]),
  )

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: BigNumber | undefined }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            const amount = value ? BigNumber.from(value.toString()) : undefined
            if (amount) {
              memo[token.contract_addr] = amount
            }
            return memo
          }, {})
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ]
}

/**
 * Returns a map of the given addresses to their eventually consistent BNB balances.
 */
export function useNativeBalances(uncheckedAddresses?: (string | undefined)[]): {
  [address: string]: BigNumber | undefined
} {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses ? orderBy(uncheckedAddresses.map(isAddress).filter((a): a is string => a !== false)) : [],
    [uncheckedAddresses],
  )
  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    useMemo(() => addresses.map((address) => [address]), [addresses]),
  )
  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: BigNumber }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = value
        return memo
      }, {}),
    [addresses, results],
  )
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (TokenListApi | undefined)[],
): (BigNumber | undefined)[] {
  const tokens = useMemo(
    () => currencies ?? [],
    [currencies],
  )
  const tokenBalances = useTokenBalances(account, tokens)
  const containsNative: boolean = useMemo(
    () => currencies?.some((currency) => currency?.contract_addr === ethers.constants.AddressZero) ?? false,
    [currencies],
  )
  const uncheckedAddresses = useMemo(() => (containsNative ? [account] : []), [containsNative, account])
  const nativeBalance = useNativeBalances(uncheckedAddresses)

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency && currency?.contract_addr !== ethers.constants.AddressZero) return tokenBalances[currency.contract_addr]
        if (currency?.contract_addr === ethers.constants.AddressZero) return nativeBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, nativeBalance, tokenBalances],
  )
}

export function useCurrencyBalance(account?: string, currency?: TokenListApi): BigNumber | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

export function useTokenBalances(
  address?: string,
  tokens?: (TokenListApi | undefined)[],
): { [tokenAddress: string]: BigNumber | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: BigNumber | undefined } {
  const wallet = useSelector(({ account }) => account.wallet);
  const { useAccount } = useWallet(wallet);
  const account = useAccount();
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
