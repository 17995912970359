import MetaMaskCard from "./MetaMaskCard";
import DisconnectWallet from "./DisconnectWallet";
import WalletConnectCard from "./WalletConnectCard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useWallet from "./useWallet";
import { getAddChainParameters } from "../../chains";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import FromTokenlistIsLoading from "../Main/From/FromTokenlistIsLoading";
import { isBrowser, isMobile } from "react-device-detect";
import CoinbaseWalletCard from "./CoinbaseWalletCard";
import BitkeepWalletCard from "./BitkeepWalletCard";
import { toast } from "react-toastify";
import WalletConnectV2Card from "./WalletConnectV2Card";
import SafePalWalletCard from "./SafePalWalletCard";
import MathWalletCard from "./MathWalletCard";

function ConnectWalletModal() {
  const dispatch = useDispatch();
  const wallet = useSelector(({ account }) => account.wallet);
  const web3Hooks = useWallet(wallet);
  const {
    useChainId,
    useAccount,
    useIsActivating,
    useIsActive,
    useProvider,
    useENSNames,
  } = web3Hooks;
  const isActive = useIsActive();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const { login } = useAuth(web3Hooks);
  const connectMetamaskHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "metamask");
    setIsLoading(false);
  };
  const connectBitkeepHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "bitkeep");
    setIsLoading(false);
  };
  const connectSafePalHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "safepal");
    setIsLoading(false);
  };
  const connectMathWalletHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "mathwallet");
    setIsLoading(false);
  };
  const connectWalletConnectHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "walletconnect");
    setIsLoading(false);
  };
  const connectWalletConnectV2Handler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "walletconnectV2");
    setIsLoading(false);
  };
  const coinbaseWalletConnectHandler = async () => {
    setIsLoading(true);
    await login(getAddChainParameters(fromChain), fromChain, "coinbasewallet");
    setIsLoading(false);
  };
  return (
    <>
      <div className='flex flex-wrap gap-1 justify-between w-[100%]'>
        {isActive ? (
          <DisconnectWallet />
        ) : isLoading ? (
          <FromTokenlistIsLoading />
        ) : (
          <>
            <MetaMaskCard handleClick={connectMetamaskHandler} />
            <BitkeepWalletCard handleClick={connectBitkeepHandler} />
            <SafePalWalletCard handleClick={connectSafePalHandler} />
            <MathWalletCard handleClick={connectMathWalletHandler} />
            <CoinbaseWalletCard handleClick={coinbaseWalletConnectHandler} />
            <WalletConnectV2Card handleClick={connectWalletConnectV2Handler} />
            <WalletConnectCard handleClick={connectWalletConnectHandler} />
          </>
        )}
      </div>
    </>
  );
}

export default ConnectWalletModal;
