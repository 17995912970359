import React from "react";
import goAheadIcon from "../../../assets/go-ahead.png";
import RouteSplitingPercentage from "./RouteSplitingPercentage";
import Token from "./Token";
import bnblightIcon from "../../../assets/img/chains/binance-light.svg";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { uniqueId } from "lodash";
import useChainInfo from "../../../hooks/useChainInfo";
function ToChain() {
  const themeMode = useSelector(({ theme }) => theme.value);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );

  const toResponseString = useSelector(
    ({ route }) => route.toResponseString
  );
  const toChain = useSelector(({ route }) => route.toChain);
  const toChainInfo = useChainInfo(toChain)
  let bigtertRoute = JSON.parse(toResponseString);
  bigtertRoute.forEach((item) => {
    // delete item.input_amount;
    // delete item.input_amount_in_usd;
    // delete item.input_amount_wei;
    // delete item.return_amount;
    // delete item.return_amount_in_usd;
    // delete item.return_amount_wei;

    // delete item.routes[0].input_amount;
    // delete item.routes[0].input_amount_wei;
    // delete item.routes[0].return_amount;
    // delete item.routes[0].return_amount_wei;
    // delete item.routes[0].operations;
    item.routes[0].operations_seperated[0].operations.forEach((item) => {
      delete item.amount_in;
      delete item.amount_in_wei;
      delete item.amount_out;
      delete item.amount_out_wei;
    });
  });
  const result = bigtertRoute.filter(
    (thing, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          JSON.stringify(t.routes[0].operations_seperated) ===
          JSON.stringify(thing.routes[0].operations_seperated)
      )
  );

  function count() {
    let array_elements = result.map((item) => {
      return JSON.stringify(item);
    });
    array_elements.sort();

    let array_elements2 = bigtertRoute.map((item) => {
      return JSON.stringify(item);
    });
    array_elements2.sort();
    var array = [];
    for (var i = 0; i < array_elements.length; i++) {
      var cnt = 0;
      var sum = 0;
      for (var j = 0; j < array_elements2.length; j++) {
        if (
          JSON.stringify(
            JSON.parse(array_elements[i]).routes[0].operations_seperated
          ) ===
          JSON.stringify(
            JSON.parse(array_elements2[j]).routes[0].operations_seperated
          )
        ) {
          sum += JSON.parse(array_elements2[j]).routes[0].input_amount;
          cnt++;
        }
      }
      array.push([array_elements[i], cnt, sum]);
    }
    return array;
  }
  let modifiedArray = count();

  return (
    <div
      id='to-chain'
      className='relative w-[100%] h-[auto] flex flex-col items-center'
    >
      <>
        {!isMobile && (
          <>
            <div className='absolute left-[71px] top-[15px] rotate-90'>
              <img src={goAheadIcon} alt='' width={"8px"} />
            </div>
            <svg
              className='absolute left-[65px] top-0'
              width='11'
              height={`${70 + (result.length - 1) * 150}`}
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <line
                strokeDasharray='1, 3'
                x1='10'
                y1='1'
                x2='10'
                y2={`${70 + (result.length - 1) * 150}`}
              ></line>
            </svg>
            <svg
              className='absolute right-[75px] bottom-[-50px]'
              width='11'
              height={`${230 + (result.length - 1) * 150}`}
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <line
                strokeDasharray='1, 3'
                x1='10'
                y1='100'
                x2='10'
                y2={`${230 + (result.length - 1) * 150}`}
              ></line>
            </svg>
          </>
        )}

        {modifiedArray.map((item, index) => {
          return (
            <div
              key={uniqueId()}
              className={`relative flex ${
                isMobile
                  ? `h-[45px] w-[100%] mt-[15px] justify-between ${
                      themeMode === "light" ? "bg-[#F5F5FA]" : "bg-[#22223D]"
                    }`
                  : "h-[100px] w-[85%] mt-[50px]"
              } `}
            >
              {!isMobile && (
                <svg
                  className='absolute left-[0px] top-[10px]'
                  width='100%'
                  height='20'
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <line
                    strokeDasharray='1, 3'
                    x1='0'
                    y1='10'
                    x2='100%'
                    y2='10'
                  ></line>
                </svg>
              )}
              <RouteSplitingPercentage
                percentage={`${(
                  Number(
                    (
                      item[2] / responseData.routes.bridge[0].return_amount
                    ).toFixed(2)
                  ) * 100
                ).toFixed()}%`}
              />
              <Token
                isVolumeSplitted={true}
                exchange={
                  JSON.parse(item[0]).routes[0].operations_seperated[0]
                    .operations[0].exchange
                }
                logoImage={`https://cdn.akka.finance/images/${toChainInfo.apiKey}/${
                  JSON.parse(item[0]).routes[0].operations_seperated[0]
                    .operations[0].offer_token[0]
                }.png`}
                symbol={
                  JSON.parse(item[0]).routes[0].operations_seperated[0]
                    .operations[0].offer_token[3]
                }
              />
              {!isMobile && (
                <div className='mx-auto mt-[15px] w-[20px]'>
                  <img src={goAheadIcon} alt='' width={"8px"} />
                </div>
              )}
              {JSON.parse(
                item[0]
              ).routes[0].operations_seperated[0].operations.map(
                (item, index) => {
                  return (
                    <React.Fragment key={uniqueId()}>
                      {isMobile && (
                        <div className='mx-auto mt-[18px] w-[8px]'>
                          <img src={goAheadIcon} alt='' width={"8px"} />
                        </div>
                      )}
                      <Token
                        isVolumeSplitted={true}
                        exchange={item.exchange}
                        logoImage={`https://cdn.akka.finance/images/${toChainInfo.apiKey}/${item.ask_token[0]}.png`}
                        symbol={item.ask_token[3]}
                      />
                      {!isMobile && (
                        <div className='mx-auto mt-[15px] w-[20px]'>
                          <img src={goAheadIcon} alt='' width={"8px"} />
                        </div>
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </div>
          );
        })}
      </>
    </div>
  );
}

export default ToChain;
