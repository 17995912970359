import { ChainId } from "../config/constants/types";

export const getMulticallAddress = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BRISE:
      return "0x2490b172F7de4f518713fB03E6D3f57B558c9964";

    case ChainId.BSC:
      return "0xcA11bde05977b3631167028862bE2a173976CA11";

    case ChainId.ETH:
      return "0xcA11bde05977b3631167028862bE2a173976CA11";

    case ChainId.FTM:
      return "0xcA11bde05977b3631167028862bE2a173976CA11";

    case ChainId.TELOS:
      return "0xcA11bde05977b3631167028862bE2a173976CA11";

    case ChainId.WAN:
      return "0xC2549f886CF596533eDc2b8818Ba661FD715e3E7";

    case ChainId.XDC:
      return "0xf3a3dAf360161B2f10c645EF039C709A3Fd4Ea62";

    case ChainId.OKC:
      return "0xcA11bde05977b3631167028862bE2a173976CA11";

    case ChainId.SHARDEUM:
      return "0x83b6af7994b1c6fb77cb7b4124d1989af48700c8";

    case ChainId.CORE:
      return "0xf3a3dAf360161B2f10c645EF039C709A3Fd4Ea62";
  }
};
