import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeTheme } from "../../features/theme/themeSlice";
import lightModeIcon from "../../assets/img/theme/sun-line.png";
import darkModeIcon from "../../assets/img/theme/moon-line.png";
import { isMobile } from "react-device-detect";

function ChangeThemeButton() {
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <button
      onClick={() => {
        switch (themeMode) {
          case "light":
            dispatch(changeTheme("dark"));
            localStorage.setItem("theme", "dark");
            break;
          case "dark":
            dispatch(changeTheme("light"));
            localStorage.setItem("theme", "light");
            break;

          default:
            dispatch(changeTheme("light"));
            break;
        }
      }}
      className={`py-2 ${isMobile ? "" : "px-2"}`}
    >
      {themeMode === "light" ? (
        <img width={isMobile ? "24px" : "24px"} src={darkModeIcon} alt='' />
      ) : (
        <img width={isMobile ? "24px" : "24px"} src={lightModeIcon} alt='' />
      )}
    </button>
  );
}

export default ChangeThemeButton;
