import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ArrowDownFontDark from "../../../assets/arrow-down-sign-to-navigate.png";
import ArrowDownFontLight from "../../../assets/arrow-down-s-line.png";
import { fromNetworklistStatus } from "../../../features/modals/modalsSlice";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
const StyledButton = styled.div<{
  backgroundColor: string;
  borderColor: string;
}>`
  width: ${isMobile ? "50%" : "33.33%"};
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 14.5px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 12px;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;
const FromChangeNetworkButton: FC<{
  imageSrc: string;
  coinName: string;
}> = ({ imageSrc, coinName }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <>
      <StyledButton
        className='hover:bg-[#ffffff]/[0.2]'
        backgroundColor={
          themeMode === "light" ? "rgba(255, 255, 255, 0.02)" : "#393E46"
        }
        borderColor={themeMode === "light" ? "#E1DEF2" : "#2f2e3c"}
        onClick={() => dispatch(fromNetworklistStatus(true))}
      >
        <div className='w-[35%] max-w-[20px]'>
          <img src={imageSrc} alt='' className='w-[20px] rounded-[50%]' />
        </div>
        <div
          className={`mx-2 w-[65%] text-center font-outfit ${themeMode === "light" ? "text-[#22223D]" : "text-[#eeeeee]"
            } text-[14px]`}
        >
          <h3>{coinName}</h3>
        </div>
        <div className='w-[10%]'>
          <img
            src={themeMode === "light" ? ArrowDownFontLight : ArrowDownFontDark}
            alt=''
            className='w-[10px]'
          />
        </div>
      </StyledButton>
    </>
  );
};

export default FromChangeNetworkButton;
