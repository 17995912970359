import { ChainId } from "../config/constants/types";

export function setContractWithChainId(chainId: ChainId): string {
  if (chainId === ChainId.BSC) return process.env.REACT_APP_BSC_AKKA_CONTRACT;
  if (chainId === ChainId.FTM) return process.env.REACT_APP_FTM_AKKA_CONTRACT;
  if (chainId === ChainId.BRISE)
    return process.env.REACT_APP_BITGERT_AKKA_CONTRACT;
  if (chainId === ChainId.XDC) return process.env.REACT_APP_XDC_AKKA_CONTRACT;
  if (chainId === ChainId.WAN) return process.env.REACT_APP_WAN_AKKA_CONTRACT;
  if (chainId === ChainId.ETH)
    return process.env.REACT_APP_ETHEREUM_AKKA_CONTRACT;
  if (chainId === ChainId.TELOS)
    return process.env.REACT_APP_TELOS_AKKA_CONTRACT;
  if (chainId === ChainId.OKC) return process.env.REACT_APP_OKC_AKKA_CONTRACT;
  if (chainId === ChainId.SHARDEUM) return process.env.REACT_APP_SHARDEUM_AKKA_CONTRACT;
  if (chainId === ChainId.CORE) return process.env.REACT_APP_CORE_AKKA_CONTRACT;
}
