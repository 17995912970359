import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useClipboard from "react-use-clipboard";

function FailedTransactionModal() {
  const failedModalState = useSelector(
    ({ modals }) => modals.FailedModalState
  );
  const themeMode = useSelector(({ theme }) => theme.value);
  const dispatch = useDispatch();
  const [isCopied, setCopied] = useClipboard(failedModalState?.txHash);
  return (
    <>
      <div className='flex flex-col items-center justify-between my-1'>
        <svg
          width='72'
          height='72'
          viewBox='0 0 72 72'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='72' height='72' fill='#F6411A' fillOpacity='0.2' />
          <g clipPath='url(#clip0_2122_21533)'>
            <path
              d='M36 32.7004L47.55 21.1504L50.8493 24.4497L39.2993 35.9997L50.8493 47.5497L47.55 50.8491L36 39.2991L24.45 50.8491L21.1507 47.5497L32.7007 35.9997L21.1507 24.4497L24.45 21.1504L36 32.7004Z'
              fill='#F7411A'
            />
          </g>
          <defs>
            <clipPath id='clip0_2122_21533'>
              <rect
                width='56'
                height='56'
                fill='white'
                transform='translate(8 8)'
              />
            </clipPath>
          </defs>
        </svg>

        <span className='text-[#DB4D4D] my-3 text-center font-outfit text-[20px]'>
          The transaction has been failed, Please try again.
        </span>
        <div className='my-3 bg-[#ECEAF4] py-3 px-3 w-full flex justify-between items-center'>
          <span className='break-all font-outfit'>
            Txn Hash: {failedModalState?.txHash}
          </span>
          {isCopied ? (
            <svg
              width='20'
              height='20'
              viewBox='0 0 41 29'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.3333 22.4015L36.7813 0.951172L40.083 4.25051L15.3333 29.0002L0.484009 14.1508L3.78334 10.8515L15.3333 22.4015Z'
                fill='#17A080'
              />
            </svg>
          ) : (
            <svg
              width='20'
              height='20'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='cursor-pointer'
              onClick={() => setCopied()}
            >
              <g clipPath='url(#clip0_2122_21584)'>
                <path
                  d='M4.66667 3.99967V1.99967C4.66667 1.82286 4.7369 1.65329 4.86193 1.52827C4.98695 1.40325 5.15652 1.33301 5.33333 1.33301H13.3333C13.5101 1.33301 13.6797 1.40325 13.8047 1.52827C13.9298 1.65329 14 1.82286 14 1.99967V11.333C14 11.5098 13.9298 11.6794 13.8047 11.8044C13.6797 11.9294 13.5101 11.9997 13.3333 11.9997H11.3333V13.9997C11.3333 14.3677 11.0333 14.6663 10.662 14.6663H2.67133C2.58342 14.6669 2.49626 14.65 2.41488 14.6168C2.3335 14.5835 2.25949 14.5345 2.19711 14.4725C2.13472 14.4106 2.0852 14.3369 2.05137 14.2558C2.01754 14.1746 2.00009 14.0876 2 13.9997L2.002 4.66634C2.002 4.29834 2.302 3.99967 2.67333 3.99967H4.66667ZM3.33533 5.33301L3.33333 13.333H10V5.33301H3.33533ZM6 3.99967H11.3333V10.6663H12.6667V2.66634H6V3.99967Z'
                  fill='#525168'
                />
              </g>
              <defs>
                <clipPath id='clip0_2122_21584'>
                  <rect width='16' height='16' fill='white' />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
        <a
          className={`${themeMode === "light"
              ? "text-[#6224FB] border-[#6224FB]"
              : "text-white border-white"
            } py-4 px-2 border-solid border-[1px] w-full text-center font-clash font-medium text-[16px] my-3 rounded-[12px]`}
          target='_blank'
          href={`https://t.me/akka_finance`}
        >
          Contact with support
        </a>
      </div>
    </>
  );
}

export default FailedTransactionModal;
