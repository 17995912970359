import FromSelectChainButton from "./FromSelectChainButton";
import { ChainId } from "../../../config/constants/types";

function FromNetworklist() {
  return (
    <>
      <div className='w-[100%]'>
        <ul className='w-[100%] h-[100%] text-sm text-gray-700 dark:text-gray-200 flex gap-1 flex-wrap justify-center items-center'>
          {/* <FromSelectChainButton chainId={ChainId.ETH} /> */}
          {/* <FromSelectChainButton chainId={ChainId.BSC} /> */}
          {/* <FromSelectChainButton chainId={ChainId.FTM} /> */}
          <FromSelectChainButton chainId={ChainId.CORE} />
          {/* <FromSelectChainButton chainId={ChainId.OKC} /> */}
          <FromSelectChainButton chainId={ChainId.BRISE} />
          <FromSelectChainButton chainId={ChainId.XDC} />
          <FromSelectChainButton chainId={ChainId.WAN} />
          <FromSelectChainButton chainId={ChainId.TELOS} />
          <FromSelectChainButton chainId={ChainId.SHARDEUM} />
        </ul>
      </div>
    </>
  );
}

export default FromNetworklist;
