import { hooks as metamaskhooks } from "../../connectors/metaMask";
import { hooks as bitkeephooks } from "../../connectors/bitkeep";
import { hooks as safepalhooks } from "../../connectors/safepal";
import { hooks as mathwallethooks } from "../../connectors/mathwallet";
import { hooks as walletconnecthooks } from "../../connectors/walletConnect";
import { hooks as walletconnectV2hooks } from "../../connectors/walletConnectV2";
import { hooks as coinbasewallethooks } from "../../connectors/coinbaseWallet";
import { useEffect, useState } from "react";
import { Web3ReactHooks } from "@web3-react/core";
import { WalletName } from "../../features/account/accountSlice";

function useWallet(wallet: WalletName) {
  const [hooks, setHooks] = useState<Web3ReactHooks>(metamaskhooks);
  useEffect(() => {
    if (wallet === "metamask") {
      setHooks(metamaskhooks);
    }
    if (wallet === "bitkeep") {
      setHooks(bitkeephooks);
    }
    if (wallet === "safepal") {
      setHooks(safepalhooks);
    }
    if (wallet === "mathwallet") {
      setHooks(mathwallethooks);
    }
    if (wallet === "walletconnect") {
      setHooks(walletconnecthooks);
    }
    if (wallet === "walletconnectV2") {
      setHooks(walletconnectV2hooks);
    }
    if (wallet === "coinbasewallet") {
      setHooks(coinbasewallethooks);
    }
  }, [wallet]);

  return hooks;
}

export default useWallet;
