import { parseUnits } from "@ethersproject/units";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getRoute, getSwap } from "../../../config/api";
import {
  changeAmount,
  changeIsLoading,
  changeRecieve,
  changeResponseData,
  changeFromResponseString,
  changeToResponseString,
  changeResponseSwapData,
  changeShowRoute,
} from "../../../features/route/routeSlice";
import useWallet from "../../Wallets/useWallet";
import { toast } from 'react-toastify';
import useChainInfo from "../../../hooks/useChainInfo";
import { isMobile } from "react-device-detect";
import { changeSwapButtonState, SwapButonStates } from "../../../features/swapbutton/swapbuttonSlice";

const StyledInput = styled.input<{
  borderColor: string;
  color: string;
}>`
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  font-weight: 400;
  padding: 10px;
  display: block;
  color: ${({ color }) => color};
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid ${({ borderColor }) => borderColor};
  // border-left: 0;
  outline: none;
  width: ${isMobile ? "100%" : "33.33%"};
  height: 48px;
  min-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 12px;
  appearance: textfield;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-left: 0px;
    // border-left: 1px solid #2F2E3C;
  }
  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 14px;
  }
  :-ms-input-placeholder {
    font-size: 14px;
  }
`;
function test(n: number, decimal: number) {
  // return parseUnits(n.toFixed(decimal), decimal)
  return parseUnits(n.toFixed(decimal), decimal);
}
const FromInput: FC = () => {
  const themeMode = useSelector(({ theme }) => theme.value);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const slippageTolerance = useSelector(
    ({ route }) => route.slippageTolerance
  );
  const bridgeFee = useSelector(({ route }) => route.bridgeFee);
  const toToken = useSelector(({ route }) => route.toToken);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const toChain = useSelector(({ route }) => route.toChain);
  const amount = useSelector(({ route }) => route.amount);
  const chainId = useSelector(({ route }) => route.fromChain);
  const counter = useSelector(({ route }) => route.counter);
  const wallet = useSelector(({ account }) => account.wallet);
  const Connectedwallet = useWallet(wallet);
  const { useAccount, useChainId, useIsActive } = Connectedwallet;
  const account = useAccount();
  const isActive = useIsActive();
  const dispatch = useDispatch();
  const fromChainInfo = useChainInfo(fromChain)
  const toChainInfo = useChainInfo(toChain)
  useEffect(() => {
    if (fromToken.adress !== "" && toToken.adress !== "" && amount !== "") {
      dispatch(changeIsLoading(true));
      dispatch(changeResponseSwapData(null));
      if (!isNaN(Number(amount)) && amount !== "0" && Number(amount) !== 0) {
        dispatch(changeShowRoute(true));
        const routeController = new AbortController();
        getRoute(
          fromChainInfo.apiKey,
          toChainInfo.apiKey,
          fromToken.adress,
          toToken.adress,
          parseUnits(amount, fromToken.decimals).toString(),
          slippageTolerance,
          routeController
        )
          .then((data) => {
            if (data.status !== 200) {
              toast.error('Something went wrong');
              dispatch(changeShowRoute(false));
              return;
            }
            else {
              dispatch(changeIsLoading(false));
            }
            dispatch(
              changeFromResponseString(
                JSON.stringify(data.data.routes[fromChainInfo.id])
              )
            );
            dispatch(
              changeToResponseString(
                JSON.stringify(data.data.routes[toChainInfo.id])
              )
            );
            dispatch(changeResponseData(data.data));
            dispatch(changeShowRoute(true));
          })
          .catch((err) => {
            if (err?.request?.status === 500) {
              toast.error('Route Service Is Not Available');
              dispatch(changeShowRoute(false));
              return;
            }
            else if (err?.request?.status === 400) {
              toast.error('No Route Found');
              dispatch(changeShowRoute(false));
              return;
            } else {
              console.error(err);
            }
          })

        const swapController = new AbortController();
        isActive &&
          account &&
          getSwap(
            fromChainInfo.apiKey,
            toChainInfo.apiKey,
            fromToken.adress,
            toToken.adress,
            parseUnits(amount, fromToken.decimals).toString(),
            slippageTolerance,
            account,
            swapController,
            bridgeFee
          ).then((data) => {
            dispatch(changeResponseSwapData(data.data));
          })
            .catch((err) => {
              if (err?.request?.status === 500) {
                toast.error('Swap Service Is Not Available');
                dispatch(changeShowRoute(false));
                return;
              } else {
                console.error(err);
              }
            });
        const requestSwapRepeat = setInterval(() => {
          dispatch(
            changeSwapButtonState({
              state: SwapButonStates.REFRESH_REQUEST,
              text: "Quote Expired. Please refresh",
              isDisable: false,
            })
          );
        }, 15000)
        return () => {
          clearInterval(requestSwapRepeat)
          routeController.abort();
          swapController.abort();
        };
      }
    }
  }, [
    amount,
    fromChain,
    toChain,
    fromToken,
    toToken,
    chainId,
    counter,
    slippageTolerance,
    account,
    isActive,
    bridgeFee
  ]);
  // useEffect(() => {
  //   if (fromToken.adress !== "" && toToken.adress !== "" && amount !== "") {
  //     getPriceOfToken("BTC").then(console.log).catch(console.log);
  //   }
  // }, [amount, fromChain, toChain, fromToken, toToken, chainId, counter]);
  return (
    <>
      <StyledInput
        borderColor={themeMode === "light" ? "#E1DEF2" : "#2f2e3c"}
        className='font-outfit font-[500] text-[16px]'
        color={themeMode === "light" ? "#22223D" : "white"}
        placeholder='Sell amount'
        value={amount}
        onChange={(e) => {
          const value = e.target.value;
          if (value === ".") {
            return;
          }
          if (value.split('.')[1] && value.split('.')[1].length > fromToken.decimals) {
            return;
          }
          if (/^\d*\.?\d*$/.test(value)) {
            dispatch(changeAmount(value));
          }

          if (!/[0-9]/.test(value)) {
          }

          if (
            (fromToken.name === "BNB" && toToken.name === "WBNB") ||
            (fromToken.name === "WBNB" && toToken.name === "BNB")
          ) {
            dispatch(changeRecieve(e.target.value));
          }
        }}
      />
    </>
  );
};

export default FromInput;
