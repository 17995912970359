import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/img/close.png";
import { useDispatch } from "react-redux";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import SlippageImage from "../../../assets/img/slippage.svg";
import { changeSlippageTolerance } from "../../../features/route/routeSlice";
import { useSelector } from "react-redux";

function FromAdvanceSetting() {
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  const slippageTolerance = useSelector(({ route }) => route.slippageTolerance);

  return (
    <>
      <div className='flex flex-col items-start justify-between my-1'>
        <div className='flex justify-start items-center'>
          <span
            className={`${themeMode === "light" ? "text-[#525168]" : "text-white"
              }`}
          >
            Slipping telorance per swap
          </span>
          <img
            src={SlippageImage}
            alt='slippageimg'
            className='w-[24px] h-[24px] rounded-[50%] ml-2'
          />
        </div>
        <div className='flex justify-between w-[100%] mt-[20px]'>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.005 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.005 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.005));
            }}
          >
            0.5%
          </div>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.01 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.01 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.01));
            }}
          >
            1%
          </div>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.02 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.02 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.02));
            }}
          >
            2%
          </div>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.03 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.03 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.03));
            }}
          >
            3%
          </div>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.04 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.04 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.04));
            }}
          >
            4%
          </div>
          <div
            className={`${themeMode === "light"
              ? `${slippageTolerance === 0.05 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
              : `${slippageTolerance === 0.05 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
              } p-3 hover:bg-[#814AFB] hover:text-white cursor-pointer`}
            onClick={() => {
              dispatch(changeModalStatus(false));
              dispatch(changeSlippageTolerance(0.05));
            }}
          >
            5%
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-between my-1 px-5 py-3">
        <div className="flex justify-start items-center">
          <img
            src={TimeImage}
            alt="sdasds"
            className="w-[24px] h-[24px] rounded-[50%] mr-2"
          />
          <span>Transaction Deadline</span>
        </div>
        <div className="w-[30%]">
          <input type="text" placeholder="15min" className="w-[100%] p-2 border border-slate-300 rounded-md focus:outline-none" />
        </div>
      </div> */}
    </>
  );
}

export default FromAdvanceSetting;
