import { useDispatch } from "react-redux";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import {
    changeShowRoute,
    changeToChain,
    changeToToken,
} from "../../../features/route/routeSlice";
import { useSelector } from "react-redux";
import { uniqueId } from "lodash";
import { ChainId } from "../../../config/constants/types";
function ToSelectChainButton({ item }) {
    const dispatch = useDispatch();
    const themeMode = useSelector(({ theme }) => theme.value);

    const setTokenIcon = (chainId: ChainId) => {
        switch (chainId) {
            case ChainId.BSC:
                return "bsc";
            case ChainId.FTM:
                return "fantom";
            case ChainId.BRISE:
                return "bitgert";
            case ChainId.XDC:
                return "xdc";
            case ChainId.WAN:
                return "wan";
            case ChainId.ETH:
                return "eth";
        }
    };
    const setTokenList = (chainId: ChainId) => {
        switch (chainId) {
            case ChainId.BSC:
                return JSON.parse(localStorage.getItem("bscTokens"));
            case ChainId.FTM:
                return JSON.parse(localStorage.getItem("fantomTokens"));
            case ChainId.BRISE:
                return JSON.parse(localStorage.getItem("bitgertTokens"));
            case ChainId.XDC:
                return JSON.parse(localStorage.getItem("xdcTokens"));
            case ChainId.WAN:
                return JSON.parse(localStorage.getItem("wanTokens"));
            case ChainId.ETH:
                return JSON.parse(localStorage.getItem("ethereumTokens"));
        }
    };
    return (
        <li className='w-[33.33%] flex-1' key={uniqueId()}>
            <div
                onClick={() => {
                    dispatch(changeToChain(item.chain));
                    dispatch(changeModalStatus(false));
                    dispatch(changeShowRoute(false));
                    dispatch(
                        changeToToken({
                            name: setTokenList(item.chain)[0].name,
                            adress: setTokenList(item.chain)[0].contract_addr,
                            image: `https://cdn.akka.finance/images/${setTokenIcon(
                                item.chain
                            )}/${setTokenList(item.chain)[0].contract_addr}.png`,
                            symbol: setTokenList(item.chain)[0].symbol,
                            decimals: setTokenList(item.chain)[0].decimals,
                        })
                    );
                }}
                className={`flex flex-col items-center justify-between px-[12px] py-[15px] border-[5px] ${themeMode === "light"
                    ? "border-[#F5F5FA] bg-[#F5F5FA]"
                    : "border-[#22223D] bg-[#22223D]"
                    } mb-2 cursor-pointer hover:border-[5px] hover:border-[#814AFB] rounded-[12px]`}
            >
                <img src={item.logo} alt='' className='w-[56px] mb-2' />
                <span
                    className={`w-[60px] md:w-[100px] font-medium text-[16px] text-center ${themeMode === "light" ? "text-[#22223D]" : "text-white"
                        }`}
                >
                    {item.name}
                </span>
            </div>
        </li>
    )

}

export default ToSelectChainButton