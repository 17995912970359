import React, { FC, HTMLAttributes } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ArrowDownFont from "../../../assets/arrow-down-sign-to-navigate.png";
import DarkArrowDownFont from "../../../assets/darkarrow-down-sign-to-navigate.png";
import tokenImage from "../../../assets/img/creation.png";
import { fromTokenlistStatus } from "../../../features/modals/modalsSlice";
import useChainInfo from "../../../hooks/useChainInfo";
const StyledButton = styled.div<
  HTMLAttributes<HTMLElement> & { backgroundColor: string; borderColor: string }
>`
  width: ${isMobile ? "50%" : "33.33%"};
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 14.5px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  @media (max-width: 768px) {
    // border-right: 1px solid #2F2E3C;
  }
`;
const FromChangeChainButton: FC<{
  imageSrc: string;
  coinName: string;
  chain: string;
}> = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const chainId = useSelector(({ route }) => route.fromChain);
  const chainInfo = useChainInfo(chainId);
  return (
    <StyledButton
      className='hover:bg-[#ffffff]/[0.2]'
      backgroundColor={
        themeMode === "light" ? "rgba(255, 255, 255, 0.02)" : "#393E46"
      }
      borderColor={themeMode === "light" ? "#E1DEF2" : "#2f2e3c"}
      onClick={() => dispatch(fromTokenlistStatus(true))}
    >
      <div className='w-[25%] max-w-[20px]'>
        <img
          src={
            fromToken.adress
              ? `https://cdn.akka.finance/images/${chainInfo.apiKey}/${fromToken.adress
              }.png`
              : tokenImage
          }
          alt=''
          className='w-[20px] rounded-[50%]'
        />
      </div>
      <div
        className={`mx-2 w-[65%] text-center font-outfit ${themeMode === "light" ? "text-[#22223D]" : "text-[#eeeeee]"
          } text-[14px]`}
      >
        <h2>{fromToken.symbol === "" ? "Select Token" : fromToken.symbol}</h2>
      </div>
      <div className='w-[10%]'>
        <img
          src={themeMode === "light" ? DarkArrowDownFont : ArrowDownFont}
          alt=''
          className='w-[10px]'
        />
      </div>
    </StyledButton>
  );
};

export default FromChangeChainButton;
