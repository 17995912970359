import forbiddenImg from "./../../assets/img/kindpng_94001.png";
import { metaMask } from "../../connectors/metaMask";
import { bitkeep } from "../../connectors/bitkeep";
import { safepal } from "../../connectors/safepal";
import { mathwallet } from "../../connectors/mathwallet";
import {
  walletConnect,
} from "../../connectors/walletConnect";
import {
  walletConnectV2,
} from "../../connectors/walletConnectV2";
import { useSelector } from "react-redux";
import useWallet from "./useWallet";
import { useDispatch } from "react-redux";
import { coinbaseWallet } from "../../connectors/coinbaseWallet";
import { useAppDispatch } from "../../state";
import useChainInfo from "../../hooks/useChainInfo";
import useGetTokenList from "../../hooks/useGetTokenList";
import { useMemo } from "react";
import { useNativeBalances } from "../../state/wallet/hooks";
import { formatUnits } from "@ethersproject/units";
import convertToSixDecimal from "../../utils/convertToSixDecimal";
import { RotatingLines } from "react-loader-spinner";

function DisconnectWallet() {
  const walletName = useSelector(({ account }) => account.wallet);
  const hooks = useWallet(walletName);
  const { useIsActive } = hooks;
  const isActive = useIsActive();
  const dispatch = useAppDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  const disconnectwallet = () => {
    if (isActive && walletName === "metamask") {
      void metaMask.resetState();
    }
    if (isActive && walletName === "bitkeep") {
      void bitkeep.resetState();
    }
    if (isActive && walletName === "safepal") {
      void safepal.resetState();
    }
    if (isActive && walletName === "mathwallet") {
      void mathwallet.resetState();
    }
    if (isActive && walletName === "walletconnect") {
      void walletConnect.deactivate();
    }
    if (isActive && walletName === "walletconnectV2") {
      void walletConnectV2.deactivate();
    }
    if (isActive && walletName === "coinbasewallet") {
      void coinbaseWallet.deactivate();
    }
  };

  const chainId = useSelector(({ route }) => route.fromChain);
  const chainInfo = useChainInfo(chainId)
  const tokenlist = useGetTokenList(chainId)
  const token = tokenlist[0]

  const wallet = useSelector(({ account }) => account.wallet);
  const { useAccount } = useWallet(wallet);
  const account = useAccount();

  const uncheckedAddresses = useMemo(() => (account ? [account] : []), [account])
  const nativeBalances = useNativeBalances(uncheckedAddresses)

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div
        className={`w-full flex items-center justify-start mb-5 py-2 px-2 my-2 pl-2 rounded-[12px]`}
      >
        <div className='w-[15%] md:w-[10%] flex justify-start mr-2'>
          <img
            className='w-[32px] h-[32px] rounded-[50%]'
            alt=''
            loading='lazy'
            src={`https://cdn.akka.finance/images/${chainInfo.apiKey}/${token.contract_addr}.png`}
          />
        </div>

        <div
          className={`w-[75%] md:w-[80%] flex flex-col items-start ${themeMode === "light" ? "#22223D" : "text-white"
            } leading-[16px]`}
        >
          <div
            className={`font-outfit font-[500] text-[14px] ${themeMode === "light" ? "#22223D" : ""
              }`}
          >
            {token.symbol}
          </div>
          <div className='font-outfit font-[500] text-[14px] text-[#bfbfbf]'>
            {token.name}
          </div>
        </div>
        {account && <div className={`w-[10%] flex justify-end ${themeMode === "light" ? "text-[#9996B3]" : "text-white"}`}>{nativeBalances[account] ? convertToSixDecimal(formatUnits(nativeBalances[account], token.decimals)) : account ? <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="14"
          visible={true}
        /> : null}</div>}
      </div>
      <div
        className={`w-full flex items-center justify-center ${themeMode === "light"
          ? "bg-[#F5F5FA] text-[#22223D]"
          : "bg-[#22223D] text-white"
          } px-[12px] py-[15px] mb-2 cursor-pointer rounded-[12px]`}
        onClick={() => disconnectwallet()}
      >
        <h6 className='font-medium text-[16px]'>
          Disconnect
        </h6>
      </div>
    </div>
  );
}

export default DisconnectWallet;
