import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TokenListApi } from "../../../config/api/types";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import { changeFromToken } from "../../../features/route/routeSlice";
import useChainInfo, { ChainInfoType } from "../../../hooks/useChainInfo";
import convertToSixDecimal from "../../../utils/convertToSixDecimal";
import useWallet from "../../Wallets/useWallet";

interface FromTokenPropsType {
  token: TokenListApi
  balance: BigNumber
  themeMode: any
  chainInfo: ChainInfoType
  account: string
}

function FromToken({ token, balance, themeMode, chainInfo, account }: FromTokenPropsType) {
  const dispatch = useDispatch();
  return (
    <div
      className={`flex items-center justify-start my-1 cursor-pointer ${themeMode === "light" ? "hover:bg-[#F5F5FA]" : "hover:bg-[#22223D]"
        } py-2 px-2 my-2 pl-2 rounded-[12px]`}
      onClick={() => {
        dispatch(
          changeFromToken({
            name: token.name,
            symbol: token.symbol,
            adress: token.contract_addr,
            decimals: token.decimals,
          })
        );
        dispatch(changeModalStatus(false));
      }}
    >
      <div className='w-[15%] md:w-[10%] flex justify-start mr-2'>
        <img
          className='w-[32px] h-[32px] rounded-[50%]'
          alt=''
          loading='lazy'
          src={`https://cdn.akka.finance/images/${chainInfo.apiKey}/${token.contract_addr}.png`}
        />
      </div>

      <div
        className={`w-[75%] md:w-[80%] flex flex-col items-start ${themeMode === "light" ? "#22223D" : "text-white"
          } leading-[16px]`}
      >
        <div
          className={`font-outfit font-[500] text-[14px] ${themeMode === "light" ? "#22223D" : ""
            }`}
        >
          {token.symbol}
        </div>
        <div className='font-outfit font-[500] text-[14px] text-[#bfbfbf]'>
          {token.name}
        </div>
      </div>
      {account && <div className={`w-[10%] flex justify-end ${themeMode === "light" ? "text-[#9996B3]" : "text-white"}`}>{balance ? convertToSixDecimal(formatUnits(balance, token.decimals)) : account ? <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="14"
        visible={true}
      /> : null}</div>}
    </div>
  );
}

export default FromToken;
