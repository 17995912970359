import { TokenListApi } from "../config/api/types";
import { ChainId } from "../config/constants/types";

function useGetTokenList(chainId: ChainId): TokenListApi[] {
  switch (chainId) {
    case ChainId.ETH:
      return JSON.parse(localStorage.getItem("ethereumTokens")) || [];
    case ChainId.BSC:
      return JSON.parse(localStorage.getItem("bscTokens")) || [];
    case ChainId.FTM:
      return JSON.parse(localStorage.getItem("fantomTokens")) || [];
    case ChainId.BRISE:
      return JSON.parse(localStorage.getItem("bitgertTokens")) || [];
    case ChainId.XDC:
      return JSON.parse(localStorage.getItem("xdcTokens")) || [];
    case ChainId.WAN:
      return JSON.parse(localStorage.getItem("wanTokens")) || [];
    case ChainId.TELOS:
      return JSON.parse(localStorage.getItem("telosTokens")) || [];
    case ChainId.OKC:
      return JSON.parse(localStorage.getItem("okcTokens")) || [];
    case ChainId.CORE:
      return JSON.parse(localStorage.getItem("coreTokens")) || [];
    case ChainId.SHARDEUM:
      return JSON.parse(localStorage.getItem("shardeumTokens")) || [];
  }
}

export default useGetTokenList;
