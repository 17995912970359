import bnblightIcon from "../../../assets/img/chains/binance-light.svg";
import briseIcon from "../../../assets/img/chains/brise.png";
import xdcIcon from "../../../assets/img/chains/xdc-icon.png";
import fantomIcon from "../../../assets/img/chains/fantom.svg";
import wanIcon from "../../../assets/img/chains/wanchain.png";
import telosIcon from "../../../assets/img/chains/teloschain.png";
import okcIcon from "../../../assets/img/chains/okx.svg";
import coreIcon from "../../../assets/img/chains/core.svg";
import ethIcon from "../../../assets/img/chains/ethereum.png";
import shardeumIcon from "../../../assets/img/chains/shardeum.jpeg";
import ToSelectChainButton from "./ToSelectChainButton";
import { useSelector } from "react-redux";
import { ChainId } from "../../../config/constants/types";
import { uniqueId } from "lodash";
function ToNetworklist() {
  const createSupportedChains = (chainId: ChainId) => {
    switch (chainId) {
      case ChainId.BSC:
        return [
          { chain: 1, logo: ethIcon, name: "Eth" },
          { chain: 56, logo: bnblightIcon, name: "BNB" },
          { chain: 250, logo: fantomIcon, name: "Fantom" },
          { chain: 32520, logo: briseIcon, name: "Bitgert" },
        ];
      case ChainId.FTM:
        return [
          { chain: 1, logo: ethIcon, name: "Eth" },
          { chain: 56, logo: bnblightIcon, name: "BNB" },
          { chain: 250, logo: fantomIcon, name: "Fantom" },
        ];
      case ChainId.BRISE:
        return [
          { chain: 56, logo: bnblightIcon, name: "BNB" },
          { chain: 32520, logo: briseIcon, name: "Bitgert" },
        ];
      case ChainId.XDC:
        return [
          { chain: 50, logo: xdcIcon, name: "XDC Chain" },
        ];
      case ChainId.WAN:
        return [
          { chain: 888, logo: wanIcon, name: "WAN Chain" },
        ];
      case ChainId.ETH:
        return [
          { chain: 1, logo: ethIcon, name: "Eth" },
          { chain: 56, logo: bnblightIcon, name: "BNB" },
          { chain: 250, logo: fantomIcon, name: "Fantom" },
        ];
      case ChainId.TELOS:
        return [
          { chain: 40, logo: telosIcon, name: "Telos" },
        ];
      case ChainId.OKC:
        return [
          { chain: 66, logo: okcIcon, name: "OKT Chain" },
        ];
      case ChainId.CORE:
        return [
          { chain: 1116, logo: coreIcon, name: "Core" },
        ];
      case ChainId.SHARDEUM:
        return [
          { chain: 8081, logo: shardeumIcon, name: "Shardeum" },
        ];
    }
  };
  const fromChain = useSelector(({ route }) => route.fromChain);
  return (
    <>
      <div className='w-[100%]'>
        <ul className='w-[100%] h-[100%] text-sm text-gray-700 dark:text-gray-200 flex gap-1 flex-wrap justify-center items-center'>
          {createSupportedChains(fromChain).map((item, index) => {
            return (<ToSelectChainButton item={item} key={uniqueId()} />)
          })
          }
        </ul>
      </div>
    </>
  );
}

export default ToNetworklist;
