import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import { changeToToken } from "../../../features/route/routeSlice";
import useChainInfo from "../../../hooks/useChainInfo";

function ToToken({ token, index }) {
  const dispatch = useDispatch();
  const chainId = useSelector(({ route }) => route.toChain);
  const themeMode = useSelector(({ theme }) => theme.value);
  const chainInfo = useChainInfo(chainId)
  return (
    <div
      key={index}
      className={`flex items-center justify-start my-1 cursor-pointer ${themeMode === "light" ? "hover:bg-[#F5F5FA]" : "hover:bg-[#22223D]"
        } py-2 px-2 my-2 pl-2 rounded-[12px]`}
      onClick={() => {
        dispatch(
          changeToToken({
            name: token.name,
            symbol: token.symbol,
            adress: token.contract_addr,
            decimals: token.decimals,
          })
        );
        dispatch(changeModalStatus(false));
      }}
    >
      <div className='w-[15%] md:w-[10%] flex justify-start mr-2'>
        <img
          className='w-[32px] h-[32px] rounded-[50%]'
          alt=''
          loading='lazy'
          src={`https://cdn.akka.finance/images/${chainInfo.apiKey}/${token.contract_addr
            }.png`}
        />
      </div>

      <div
        className={`w-[75%] md:w-[80%] flex flex-col items-start ${themeMode === "light" ? "#22223D" : "text-white"
          } leading-[16px]`}
      >
        <div
          className={`font-outfit font-[500] text-[14px] ${themeMode === "light" ? "#22223D" : ""
            }`}
        >
          {token.symbol}
        </div>
        <div className='font-outfit font-[500] text-[14px] text-[#bfbfbf]'>
          {token.name}
        </div>
      </div>
      {/* <div className="w-[10%] flex justify-end text-white">0</div> */}
    </div>
  );
}

export default ToToken;
