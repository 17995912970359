import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { changeSwapButtonState, SwapButonStates } from "../../features/swapbutton/swapbuttonSlice";
import { changeApprovalState } from "../../features/account/accountSlice";
import { ApprovalState, useApproveCallbackFromTrade } from "../../hooks/useApproveCallback";
import { changeModalStatus } from "../../features/modals/modalsSlice";
import { RotatingLines } from "react-loader-spinner";

function ApproveModal() {
    const themeMode = useSelector(({ theme }) => theme.value);
    const fromToken = useSelector(({ route }) => route.fromToken);
    const inputValue = useSelector(({ route }) => route.amount);
    const dispatch = useDispatch()
    // check whether the user has approved the router on the input token
    const [approval, approveCallback] = useApproveCallbackFromTrade(
        fromToken.adress,
        inputValue
    );

    const [fullapproval, fullApproveCallback] = useApproveCallbackFromTrade(
        fromToken.adress,
        null
    );

    const approve = () => {
        dispatch(changeModalStatus(false));
        approveCallback().catch((err) => {
            toast.error(`Error happend: User rejected transaction`);
            if (err?.code === 4001) {
                dispatch(
                    changeSwapButtonState({
                        state: SwapButonStates.REFRESH_REQUEST,
                        text: "Quote Expired. Please refresh",
                        isDisable: false,
                    })
                );
            }
        });
        dispatch(changeApprovalState(ApprovalState.PENDING));
        dispatch(
            changeSwapButtonState({
                state: SwapButonStates.APPROVE,
                text: "Please wait ...",
                isDisable: true,
            })
        );
    }
    const fullApprove = () => {
        dispatch(changeModalStatus(false));
        fullApproveCallback().catch((err) => {
            toast.error(`Error happend: User rejected transaction`);
            if (err?.code === 4001) {
                dispatch(
                    changeSwapButtonState({
                        state: SwapButonStates.REFRESH_REQUEST,
                        text: "Quote Expired. Please refresh",
                        isDisable: false,
                    })
                );
            }
        });
        dispatch(changeApprovalState(ApprovalState.PENDING));
        dispatch(
            changeSwapButtonState({
                state: SwapButonStates.APPROVE,
                text: "Please wait ...",
                isDisable: true,
            })
        );
    }
    return (
        <>
            <div className='flex flex-col items-center justify-between my-1 gap-[30px]'>
                <div>
                    {themeMode === "light" ?
                        <svg width="96" height="86" viewBox="0 0 96 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 29V15C36 8.37258 41.3726 3 48 3V3C54.6274 3 60 8.37258 60 15V16V21" stroke="#814AFB" strokeWidth="6" />
                            <rect x="24" y="29" width="48" height="40" stroke="#814AFB" strokeWidth="6" />
                            <path d="M48.5 50C50.433 50 52 48.433 52 46.5C52 44.567 50.433 43 48.5 43C46.567 43 45 44.567 45 46.5C45 48.433 46.567 50 48.5 50ZM48.5 50V58.5" stroke="#814AFB" strokeWidth="6" />
                        </svg>
                        :
                        <svg width="96" height="86" viewBox="0 0 96 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 29V15C36 8.37258 41.3726 3 48 3V3C54.6274 3 60 8.37258 60 15V16V21" stroke="url(#paint0_linear_919_3036)" strokeWidth="6" />
                            <rect x="24" y="29" width="48" height="40" stroke="url(#paint1_linear_919_3036)" strokeWidth="6" />
                            <path d="M48.5 50C50.433 50 52 48.433 52 46.5C52 44.567 50.433 43 48.5 43C46.567 43 45 44.567 45 46.5C45 48.433 46.567 50 48.5 50ZM48.5 50V58.5" stroke="url(#paint2_linear_919_3036)" strokeWidth="6" />
                            <defs>
                                <linearGradient id="paint0_linear_919_3036" x1="35.1852" y1="29.0135" x2="60.963" y2="29.0135" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#6C6289" />
                                    <stop offset="1" stop-color="#D6C1FC" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_919_3036" x1="22.3704" y1="69.0208" x2="73.9259" y2="69.0208" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#6C6289" />
                                    <stop offset="1" stop-color="#D6C1FC" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_919_3036" x1="44.7809" y1="58.5081" x2="44.7806" y2="43" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#6C6289" />
                                    <stop offset="1" stop-color="#D6C1FC" />
                                </linearGradient>
                            </defs>
                        </svg>
                    }

                </div>
                <div>
                    <p className={`text-center text-[16px] ${themeMode === "light" ? "text-[#22223D]" : "text-[#ECEAF4]"}`}>
                        Our smart contract needs your permission in order to move <span className="text-[24px]">{inputValue}</span> {fromToken.symbol} on your behalf.
                    </p>
                </div>
                {approval !== ApprovalState.UNKNOWN && fullapproval !== ApprovalState.UNKNOWN ?
                    <div className="flex flex-col w-full gap-[20px]">
                        <div className={`text-[#ECEAF4] bg-[#6100FF] w-full flex justify-center items-center py-[16px] rounded-[12px] font-clash font-medium cursor-pointer`} onClick={() => fullApprove()}>
                            Unlock permanently
                        </div>
                        <div className={`text-[#ECEAF4] bg-[#6100FF] w-full flex justify-center items-center py-[16px] rounded-[12px] font-clash font-medium cursor-pointer`} onClick={() => approve()}>
                            Unlock this time only
                        </div>
                    </div> :
                    <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="36"
                        visible={true}
                    />
                }

            </div>
        </>
    );
}

export default ApproveModal;
