import { createSlice } from "@reduxjs/toolkit";
import { ChainId, RouteResponseDto } from "../../config/constants/types";
interface FromtokenType {
  name: string;
  adress: string;
  image: string;
  symbol: string;
  decimals: number;
}
interface totokenType {
  name: string;
  adress: string;
  image: string;
  symbol: string;
  decimals: number;
}

interface RouteState {
  isLoading: boolean;
  fromChain: ChainId;
  fromToken: FromtokenType;
  toChain: ChainId;
  toToken: totokenType;
  amount: string;
  recieve: number | string;
  showRoute: boolean;
  counter: number;
  responseData: RouteResponseDto;
  responseSwapData: any;
  swapDescription: string;
  oneChainSwapDesc: string;
  quoteLayerZeroFeeSwapDesc: string;
  payloadEncodeSwapDesc: string;
  fromResponseString: string;
  toResponseString: string;
  slippageTolerance: number;
  bridgeFee: number;
}
const initialState: RouteState = {
  isLoading: false,
  fromChain: ChainId.FTM,
  fromToken: {
    name: "Fantom",
    adress: "0x0000000000000000000000000000000000000000",
    image: "",
    symbol: "FTM",
    decimals: 18,
  },
  toChain: ChainId.BSC,
  toToken: {
    name: "BNB",
    adress: "0x0000000000000000000000000000000000000000",
    image: "",
    symbol: "BNB",
    decimals: 18,
  },
  amount: "",
  recieve: "",
  showRoute: false,
  counter: 0,
  swapDescription: null,
  oneChainSwapDesc: null,
  quoteLayerZeroFeeSwapDesc: null,
  payloadEncodeSwapDesc: null,
  responseData: null,
  responseSwapData: null,
  fromResponseString: "[]",
  toResponseString: "[]",
  slippageTolerance: 0.03,
  bridgeFee: 500000,
};
export const chainsSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeFromChain: (state, action) => {
      state.fromChain = action.payload;
    },
    changeFromToken: (state, action) => {
      state.fromToken = action.payload;
    },
    changeToChain: (state, action) => {
      state.toChain = action.payload;
    },
    changeToToken: (state, action) => {
      state.toToken = action.payload;
    },
    changeAmount: (state, action) => {
      state.amount = action.payload;
    },
    changeRecieve: (state, action) => {
      state.recieve = action.payload;
    },
    changeShowRoute: (state, action) => {
      state.showRoute = action.payload;
    },
    changeResponseData: (state, action) => {
      state.responseData = action.payload;
    },
    changeResponseSwapData: (state, action) => {
      state.responseSwapData = action.payload;
    },
    changeCounter: (state, action) => {
      state.counter = action.payload;
    },
    changeFromResponseString: (state, action) => {
      state.fromResponseString = action.payload;
    },
    changeToResponseString: (state, action) => {
      state.toResponseString = action.payload;
    },
    changeSwapDescription: (state, action) => {
      state.swapDescription = action.payload;
    },
    changeOneChainSwapDesc: (state, action) => {
      state.oneChainSwapDesc = action.payload;
    },
    changeQuoteLayerZeroFeeSwapDesc: (state, action) => {
      state.quoteLayerZeroFeeSwapDesc = action.payload;
    },
    changePayloadEncodeSwapDesc: (state, action) => {
      state.payloadEncodeSwapDesc = action.payload;
    },
    clearRouteAfterSwap: () => initialState,
    changeSlippageTolerance: (state, action) => {
      state.slippageTolerance = action.payload;
    },
    changeBridgeFee: (state, action) => {
      state.bridgeFee = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeIsLoading,
  changeFromChain,
  changeFromToken,
  changeToChain,
  changeToToken,
  changeAmount,
  changeRecieve,
  changeShowRoute,
  changeResponseData,
  changeResponseSwapData,
  changeCounter,
  changeFromResponseString,
  changeToResponseString,
  changeSwapDescription,
  changeOneChainSwapDesc,
  changeQuoteLayerZeroFeeSwapDesc,
  changePayloadEncodeSwapDesc,
  clearRouteAfterSwap,
  changeSlippageTolerance,
  changeBridgeFee,
} = chainsSlice.actions;

export default chainsSlice.reducer;
