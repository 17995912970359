import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import GeneralModal from "./components/Modals/GeneralModal";
import useWallet from "./components/Wallets/useWallet";
import { metaMask } from "./connectors/metaMask";
import { bitkeep } from "./connectors/bitkeep";
import { safepal } from "./connectors/safepal";
import { mathwallet } from "./connectors/mathwallet";
import { walletConnect } from "./connectors/walletConnect";
import { walletConnectV2 } from "./connectors/walletConnectV2";
import { changeAddress, changeWallet, WalletName } from "./features/account/accountSlice";
import { changeFromChain, changeFromToken, changeToChain, changeToToken } from "./features/route/routeSlice";
import { isMobile } from "react-device-detect";
import { coinbaseWallet } from "./connectors/coinbaseWallet";
import bgDark from './assets/bg-dark.png'
import bgLight from './assets/bg-light.png'

function App() {
  const chainId = useSelector(({ route }) => route.fromChain);
  const dispatch = useDispatch();
  const wallet = useSelector(({ account }) => account.wallet);
  const themeMode = useSelector(({ theme }) => theme.value);
  const { useAccount } = useWallet(wallet);
  const account = useAccount();

  useEffect(() => {
    switch (localStorage.getItem("wallet")) {
      case "metamask":
        void metaMask
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("metamask"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to metamask");
          });
        break;

      case "bitkeep":
        void bitkeep
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("bitkeep"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to bitkeep");
          });
        break;

      case "safepal":
        void safepal
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("safepal"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to bitkeep");
          });
        break;

      case "mathwallet":
        void mathwallet
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("mathwallet"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to bitkeep");
          });
        break;

      case "walletconnect":
        void walletConnect
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("walletconnect"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to walletConnect");
          });
        break;

      case "walletconnectV2":
        void walletConnectV2
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("walletconnectV2"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to walletconnectV2");
          });
        break;

      case "coinbasewallet":
        void coinbaseWallet
          .connectEagerly()
          .then(() => {
            dispatch(changeWallet("coinbasewallet"));
            dispatch(changeFromChain(chainId));
            dispatch(changeAddress(account));
          })
          .catch(() => {
            console.debug("Failed to connect eagerly to walletConnect");
          });
        break;
    }
  }, [chainId]);

  return (
    <div
      className={`min-h-screen relative overflow-hidden ${themeMode === "light" ? "bg-[#F5F5FA]" : "bg-[#22223d]"
        }`}
    >
      {themeMode === "dark" && !isMobile && <img src={bgDark} alt="" className="absolute w-full" />}
      {themeMode === "light" && !isMobile && <img src={bgLight} alt="" className="absolute w-full" />}

      {/* {themeMode === "light" && !isMobile && (
        <svg width="1440" height="1660" viewBox="0 0 1440 1660" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[200px]">
          <g opacity="0.2" filter="url(#filter0_f_2534_16753)">
            <circle cx="719.5" cy="794.5" r="376.5" fill="#814AFB" />
          </g>
          <defs>
            <filter id="filter0_f_2534_16753" x="-434" y="-359" width="2307" height="2307" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="388.5" result="effect1_foregroundBlur_2534_16753" />
            </filter>
          </defs>
        </svg>
      )}
      {themeMode === "light" && !isMobile && (
        <svg width="1318" height="1660" viewBox="0 0 1318 1660" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-0 top-[500px]">
          <g opacity="0.4" filter="url(#filter0_f_2534_16489)">
            <circle cx="270.5" cy="956.5" r="270.5" fill="#814AFB" />
          </g>
          <defs>
            <filter id="filter0_f_2534_16489" x="-777" y="-91" width="2095" height="2095" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="388.5" result="effect1_foregroundBlur_2534_16489" />
            </filter>
          </defs>
        </svg>
      )}
      {themeMode === "dark" && !isMobile && (
        <svg width="1440" height="1948" viewBox="0 0 1440 1948" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[200px]">
          <g opacity="0.2" filter="url(#filter0_f_2531_16497)">
            <circle cx="719.5" cy="794.5" r="376.5" fill="#814AFB" />
          </g>
          <defs>
            <filter id="filter0_f_2531_16497" x="-434" y="-359" width="2307" height="2307" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="388.5" result="effect1_foregroundBlur_2531_16497" />
            </filter>
          </defs>
        </svg>
      )} */}
      {
        !isMobile && (
          <svg width="241" height="573" viewBox="0 0 241 573" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-0 top-[500px]">
            <g opacity="0.4">
              <path opacity="0.2" d="M215 286.002C215 237.96 201.715 190.854 176.613 149.891C151.511 108.929 115.571 75.7054 72.765 53.8948C29.9593 32.0841 -18.0442 22.5357 -65.9381 26.305C-113.832 30.0743 -159.751 47.0146 -198.618 75.253C-237.484 103.491 -267.785 141.928 -286.17 186.313C-304.555 230.698 -310.308 279.303 -302.792 326.753C-295.277 374.204 -274.786 418.652 -243.585 455.183C-212.384 491.715 -171.689 518.906 -125.998 533.752L-124.388 528.797C-169.165 514.248 -209.047 487.6 -239.624 451.8C-270.2 415.999 -290.281 372.44 -297.646 325.938C-305.012 279.437 -299.374 231.804 -281.357 188.307C-263.34 144.809 -233.645 107.142 -195.555 79.468C-157.466 51.7944 -112.465 35.1929 -65.5293 31.4989C-18.5933 27.805 28.4501 37.1625 70.3997 58.5369C112.349 79.9113 147.571 112.47 172.171 152.613C196.771 192.757 209.791 238.921 209.791 286.002H215Z" fill="#9A76DC" />
              <path opacity="0.2" d="M180.043 346.435C191.189 304.84 190.615 260.973 178.384 219.684C166.154 178.394 142.744 141.291 110.742 112.477C78.74 83.6623 39.3933 64.2586 -2.9482 56.4111C-45.2897 48.5636 -88.9767 52.5778 -129.179 68.0101C-169.382 83.4423 -204.533 109.691 -230.748 143.855C-256.963 178.019 -273.22 218.767 -277.721 261.594C-282.222 304.42 -274.793 347.658 -256.254 386.525C-237.715 425.393 -208.789 458.377 -172.673 481.831L-170.13 477.914C-205.523 454.93 -233.871 422.605 -252.039 384.515C-270.207 346.425 -277.488 304.052 -273.077 262.082C-268.665 220.112 -252.734 180.179 -227.043 146.698C-201.353 113.218 -166.904 87.4935 -127.506 72.3699C-88.1072 57.2463 -45.2939 53.3123 -3.79923 61.0029C37.6955 68.6935 76.2552 87.7091 107.617 115.947C138.979 144.186 161.921 180.546 173.906 221.01C185.892 261.473 186.455 304.463 175.533 345.227L180.043 346.435Z" fill="#9A76DC" />
              <path opacity="0.2" d="M97.4998 429.002C123.872 402.629 142.438 369.477 151.145 333.212C159.852 296.946 158.36 258.979 146.835 223.508C135.309 188.037 114.2 156.444 85.8393 132.222C57.479 108 22.9727 92.0921 -13.8645 86.2576C-50.7016 80.4232 -88.4347 84.8892 -122.892 99.1619C-157.349 113.435 -187.189 136.958 -209.111 167.131C-231.033 197.305 -244.184 232.953 -247.111 270.134C-250.037 307.315 -242.624 344.582 -225.692 377.813L-222.088 375.977C-238.682 343.41 -245.946 306.889 -243.078 270.451C-240.211 234.014 -227.323 199.079 -205.839 169.509C-184.355 139.939 -155.112 116.886 -121.344 102.899C-87.5761 88.9114 -50.5976 84.5347 -14.4972 90.2525C21.6032 95.9702 55.4194 111.56 83.2125 135.297C111.006 159.035 131.693 189.996 142.988 224.757C154.283 259.519 155.744 296.727 147.212 332.267C138.679 367.808 120.485 400.297 94.6398 426.142L97.4998 429.002Z" fill="#9A76DC" />
              <path opacity="0.2" d="M-2.49286 446.51C27.1085 438.578 53.94 422.615 75.035 400.386C96.1301 378.156 110.667 350.526 117.039 320.55C123.41 290.574 121.368 259.42 111.139 230.532C100.909 201.644 82.8898 176.148 59.0737 156.862C35.2576 137.576 6.57206 125.252 -23.8114 121.252C-54.1948 117.252 -85.0927 121.732 -113.089 134.196C-141.085 146.661 -165.089 166.625 -182.447 191.881C-199.805 217.137 -209.84 246.701 -211.444 277.304L-208.125 277.478C-206.554 247.487 -196.719 218.514 -179.708 193.763C-162.697 169.012 -139.173 149.448 -111.737 137.232C-84.3008 125.017 -54.021 120.627 -24.2452 124.547C5.53061 128.467 33.6424 140.545 56.9822 159.445C80.322 178.345 97.9806 203.331 108.006 231.641C118.031 259.952 120.032 290.483 113.788 319.859C107.544 349.235 93.2975 376.313 72.6243 398.098C51.9512 419.883 25.6564 435.527 -3.35302 443.3L-2.49286 446.51Z" fill="#9A76DC" />
            </g>
          </svg>

        )
      }
      {
        !isMobile && (
          <svg width="296" height="573" viewBox="0 0 296 573" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-0 top-[800px]">
            <g opacity="0.4">
              <path opacity="0.2" d="M546.502 286.002C546.502 237.96 533.217 190.854 508.115 149.891C483.013 108.929 447.073 75.7054 404.267 53.8948C361.461 32.0841 313.458 22.5357 265.564 26.305C217.67 30.0743 171.751 47.0146 132.884 75.253C94.0176 103.491 63.7167 141.928 45.3318 186.313C26.9469 230.698 21.1942 279.303 28.7096 326.753C36.225 374.204 56.7159 418.652 87.9167 455.183C119.117 491.715 159.813 518.906 205.503 533.752L207.113 528.797C162.337 514.248 122.455 487.6 91.8784 451.8C61.3016 415.999 41.2206 372.44 33.8555 325.938C26.4904 279.437 32.128 231.804 50.1452 188.307C68.1624 144.809 97.8573 107.142 135.947 79.468C174.036 51.7944 219.037 35.1929 265.973 31.4989C312.909 27.805 359.952 37.1625 401.902 58.5369C443.851 79.9113 479.073 112.47 503.673 152.613C528.273 192.757 541.292 238.921 541.292 286.002H546.502Z" fill="#9A76DC" />
              <path opacity="0.2" d="M511.546 346.435C522.691 304.84 522.117 260.973 509.886 219.684C497.656 178.394 474.246 141.291 442.244 112.477C410.242 83.6623 370.896 64.2586 328.554 56.4111C286.212 48.5636 242.526 52.5778 202.323 68.0101C162.121 83.4423 126.969 109.691 100.754 143.855C74.5392 178.019 58.2824 218.767 53.7811 261.594C49.2799 304.42 56.7094 347.658 75.2483 386.525C93.7873 425.393 122.713 458.377 158.829 481.831L161.372 477.914C125.979 454.93 97.6315 422.605 79.4634 384.515C61.2952 346.425 54.0143 304.052 58.4256 262.082C62.8368 220.112 78.7684 180.179 104.459 146.698C130.15 113.218 164.598 87.4935 203.997 72.3699C243.395 57.2463 286.208 53.3123 327.703 61.0029C369.198 68.6935 407.757 87.7091 439.119 115.947C470.481 144.186 493.423 180.546 505.409 221.01C517.395 261.473 517.957 304.463 507.035 345.227L511.546 346.435Z" fill="#9A76DC" />
              <path opacity="0.2" d="M429.002 429.002C455.374 402.629 473.94 369.477 482.647 333.212C491.353 296.946 489.862 258.979 478.336 223.508C466.811 188.037 445.701 156.444 417.341 132.222C388.981 108 354.474 92.0921 317.637 86.2576C280.8 80.4232 243.067 84.8892 208.61 99.1619C174.152 113.435 144.313 136.958 122.391 167.131C100.469 197.305 87.3173 232.953 84.391 270.134C81.4648 307.315 88.8775 344.582 105.81 377.813L109.414 375.977C92.82 343.41 85.5555 306.889 88.4232 270.451C91.291 234.014 104.179 199.079 125.663 169.509C147.147 139.939 176.389 116.886 210.157 102.899C243.926 88.9114 280.904 84.5347 317.005 90.2525C353.105 95.9702 386.921 111.56 414.714 135.297C442.507 159.035 463.195 189.996 474.49 224.757C485.784 259.519 487.246 296.727 478.714 332.267C470.181 367.808 451.987 400.297 426.141 426.142L429.002 429.002Z" fill="#9A76DC" />
              <path opacity="0.2" d="M329.009 446.51C358.61 438.578 385.442 422.615 406.537 400.386C427.632 378.156 442.169 350.526 448.541 320.55C454.912 290.574 452.87 259.42 442.64 230.532C432.411 201.644 414.392 176.148 390.576 156.862C366.76 137.576 338.074 125.252 307.691 121.252C277.307 117.252 246.409 121.732 218.413 134.196C190.417 146.661 166.413 166.625 149.055 191.881C131.697 217.137 121.662 246.701 120.058 277.304L123.377 277.478C124.948 247.487 134.783 218.514 151.794 193.763C168.805 169.012 192.329 149.448 219.765 137.232C247.201 125.017 277.481 120.627 307.257 124.547C337.033 128.467 365.144 140.545 388.484 159.445C411.824 178.345 429.483 203.331 439.508 231.641C449.533 259.952 451.534 290.483 445.29 319.859C439.046 349.235 424.799 376.313 404.126 398.098C383.453 419.883 357.158 435.527 328.149 443.3L329.009 446.51Z" fill="#9A76DC" />
            </g>
          </svg>
        )
      }
      <Header />
      <Main />
      <Footer />
      <GeneralModal />
    </div>
  );
}

export default App;
