import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  changeModalStatus,
  connectWalletStatus,
} from "../../features/modals/modalsSlice";
import summarizeString from "../../hooks/summarizeString";
import useWallet from "../Wallets/useWallet";

function ConnectWalletButton() {
  const wallet = useSelector(({ account }) => account.wallet);
  const {
    useChainId,
    useAccount,
    useIsActivating,
    useIsActive,
    useProvider,
    useENSNames,
  } = useWallet(wallet);
  const isActive = useIsActive();
  const account = useAccount();

  const dispatch = useDispatch();
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <button
      onClick={() => {
        dispatch(connectWalletStatus(true));
      }}
      className={`py-2  ${isMobile ? "px-3 text-[14px]" : "px-5 text-[16px]"
        } font-clash font-[400] border-[1px] border-solid hover:border-[1px] rounded-[12px] ${themeMode === "light"
          ? "text-black border-[#814AFB] hover:border-[black]"
          : "text-white border-white hover:border-[#814AFB]"
        }`}
    >
      {isActive ? summarizeString(account, 6, "...") : "Connect Wallet"}
    </button>
  );
}

export default ConnectWalletButton;
