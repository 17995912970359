import React from "react";
import { useSelector } from "react-redux";
import useChainInfo from "../../../hooks/useChainInfo";

function FromToValue({ logoImage, amount, symbol, chain }) {
  const themeMode = useSelector(({ theme }) => theme.value);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const toChain = useSelector(({ route }) => route.toChain);
  const fromChainInfo = useChainInfo(fromChain)
  const toChainInfo = useChainInfo(toChain)
  return (
    <div
      className={`flex justify-between items-center h-[50px] px-[15px] py-[25px] ${themeMode === "light"
        ? "bg-[#F5F5FA] text-[#22223D]"
        : "bg-[#22223D] text-white"
        } border-[1px] border-solid border-[#9996b366] rounded-[12px]`}
    >
      <div className='p-1'>
        <img
          src={`https://cdn.akka.finance/images/${chain === "from" ? fromChainInfo.apiKey : toChainInfo.apiKey
            }/${logoImage}.png`}
          alt=''
          width={"25px"}
          className='rounded-[50%]'
        />
      </div>
      <div className='p-1'>
        <span>{amount}</span>
      </div>
      <div className='p-1'>
        <span>{symbol}</span>
      </div>
    </div>
  );
}

export default FromToValue;
