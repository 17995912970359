import { BigNumber, Contract, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CHAINS } from "../chains";
import useWallet from "../components/Wallets/useWallet";
import { ERC20_ABI } from "../config/abi/erc20";
import { changeBalanceLoader } from "../features/account/accountSlice";
import { changeSwapButtonState } from "../features/swapbutton/swapbuttonSlice";
import { useTokenContract } from "./useContract";

function useTokenBalance(
  tokenAdress?: string,
  owner?: string
): BigNumber | undefined {
  const wallet = useSelector(({ account }) => account.wallet);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const amount = useSelector(({ route }) => route.amount);
  const [balance, setBalance] = useState<BigNumber>(undefined);
  const { useChainId } = useWallet(wallet);
  const chainId = useChainId();
  const provider = new ethers.providers.JsonRpcProvider(CHAINS[fromChain].urls[0])
  const contract = new Contract(tokenAdress, ERC20_ABI, provider)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeBalanceLoader(true))
    if (owner && tokenAdress) {
      if (
        tokenAdress === ethers.constants.AddressZero
      ) {
        provider.getBalance(owner).then((balance) => {
          setBalance(balance);
        }).then(() => {
          dispatch(changeBalanceLoader(false))
        });
      } else {
        contract.balanceOf(owner).then((data) => {
          setBalance(data);
        }).then(() => {
          dispatch(changeBalanceLoader(false))
        });

      }
    }
  }, [owner, tokenAdress, fromChain, chainId, fromToken, amount]);

  return useMemo(
    () => (tokenAdress && balance ? balance : undefined),
    [tokenAdress, balance, owner, fromChain, chainId, fromToken]
  );
}

export default useTokenBalance;
