import { BigNumber, Contract, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CHAINS } from "../chains";
import useWallet from "../components/Wallets/useWallet";
import { ERC20_ABI } from "../config/abi/erc20";

import { useTokenContract } from "./useContract";

function useTokenAllowance(
  tokenAdress?: string,
  owner?: string,
  spender?: string
): BigNumber | undefined {
  const [allowance, setAllowance] = useState<BigNumber>(undefined);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const amount = useSelector(({ route }) => route.amount);
  const counter = useSelector(({ route }) => route.counter);
  const provider = new ethers.providers.JsonRpcProvider(CHAINS[fromChain].urls[0])
  const contract = new Contract(tokenAdress, ERC20_ABI, provider)
  useEffect(() => {
    if (owner && tokenAdress && spender) {
      if (
        tokenAdress === ethers.constants.AddressZero
      ) {
        provider.getBalance(owner).then((balance) => {
          setAllowance(balance);
        });
      }
      else {
        contract.allowance(owner, spender).then((data) => {
          setAllowance(data);
        });
      }
    }
  }, [owner, tokenAdress, spender, amount, fromToken, counter]);

  return useMemo(
    () => (tokenAdress && allowance ? allowance : undefined),
    [tokenAdress, allowance]
  );
}

export default useTokenAllowance;
