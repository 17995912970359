import { TransactionResponse } from "@ethersproject/providers";
import { createClient } from '@layerzerolabs/scan-client';
import {
  parseUnits,
} from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useSWRImmutable from "swr/immutable";
import NoiseIcon from "../../assets/img/Noise.png";
import { Weth } from "../../config/abi/types";
import {
  ChainId,
  NetworkName,
} from "../../config/constants/types";
import { changeApprovalState, changeApprovevalue } from "../../features/account/accountSlice";
import { ApproveStatus, connectWalletStatus } from "../../features/modals/modalsSlice";
import {
  changeAmount,
  changeBridgeFee,
  changeCounter,
  changeFromChain,
  changeFromToken,
  changeResponseData,
  changeShowRoute,
  changeSlippageTolerance,
  changeToChain,
  changeToToken,
} from "../../features/route/routeSlice";
import { changeSwapButtonState, SwapButonStates } from "../../features/swapbutton/swapbuttonSlice";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import { useCallWithoutGasPrice } from "../../hooks/useCallWithoutGasPrice";
import useTokenBalance from "../../hooks/useTokenBalance";
import MyLoader from "../MyLoader";
import useWallet from "../Wallets/useWallet";
import FromBox from "./From/FromBox";
import Route from "./Route/Route";
import SwitchBox from "./Switcher/SwitchBox";
import ToBox from "./To/ToBox";
import FromRefresh from "./From/FromRefresh";
import FromAdvanceSettingButton from "./From/FromAdvanceSettingButton";
import InfoBox from "./Info/InfoBox";
import {
  useAkkaBitgertAggrigatorSwapCallback,
} from "../../hooks/useAkkaBitgertCallback";
import { isMobile } from "react-device-detect";
import { getTokenlist } from "../../config/api";
import { setContractWithChainId } from "../../hooks/useSetContractWithChainId";
import { toast } from 'react-toastify';
import useChainInfo from "../../hooks/useChainInfo";
import useGetTokenList from "../../hooks/useGetTokenList";
import { getAddChainParameters } from "../../chains";
import useAuth from "../../hooks/useAuth";
import useTokenAllowance from "../../hooks/useTokenAllowance";
import { MaxUint256 } from "../../config/constants";

const Inner = styled.div`
  &:before,
  &:after {
    content: "";
    display: table;
  }
`;
const MainStyled = styled.main<{ bgcolor: string }>`
  background-color: ${({ bgcolor }) => bgcolor};
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center top;
  padding-top: 20px;
  @media (min-width: 960px) {
    min-height: 75vh;
    // padding: 20px;
  }
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;
function Main() {
  const isLoadingRoute = useSelector(({ route }) => route.isLoading);
  const inputValue = useSelector(({ route }) => route.amount);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const toToken = useSelector(({ route }) => route.toToken);
  const fromChain = useSelector(({ route }) => route.fromChain);
  const toChain = useSelector(({ route }) => route.toChain);
  const amount = useSelector(({ route }) => route.amount);
  const responseData = useSelector(
    ({ route }) => route.responseData
  );
  const counter = useSelector(({ route }) => route.counter);
  const route = useSelector(({ route }) => route);
  const slippageTolerance = useSelector(({ route }) => route.slippageTolerance);
  const bridgeFee = useSelector(({ route }) => route.bridgeFee);
  const oneChainSwapDesc = useSelector(
    ({ route }) => route.responseSwapData
  );
  const wallet = useSelector(({ account }) => account.wallet);
  const swapButtonData = useSelector(
    ({ swapbutton }) => swapbutton.value
  );
  const {
    useChainId,
    useAccount,
    useIsActive,
    useIsActivating
  } = useWallet(wallet);
  const isActive = useIsActive();
  const isActivating = useIsActivating();
  const walletChainId = useChainId();
  const account = useAccount();
  const dispatch = useDispatch();
  const { multiPathSwap, coreMultiPathSwap } = useAkkaBitgertAggrigatorSwapCallback();
  const approveState = useSelector(
    ({ account }) => account.approveState
  );
  const transactions = useSelector(
    ({ transactions }) => transactions
  );

  const chainId = useSelector(({ route }) => route.fromChain);
  const balance = useTokenBalance(fromToken.adress, account);

  const isButtonDisable = {
    disabled: swapButtonData.isDisable ? true : false,
  };
  const web3Hooks = useWallet(wallet);
  const { login } = useAuth(web3Hooks);
  const chainInfo = useChainInfo(fromChain)
  const tokenList = useGetTokenList(fromChain)
  const currentAllowance = useTokenAllowance(
    fromToken.adress,
    account ?? undefined,
    setContractWithChainId(fromChain)
  );
  const changeChainId = async (chainid: ChainId) => {
    await login(getAddChainParameters(chainid), chainid, wallet).then(() => {
      dispatch(
        changeSwapButtonState({
          state: SwapButonStates.ENTER_AMOUNT,
          text: "Enter Amount",
          isDisable: true,
        })
      );
    });
    dispatch(changeFromChain(chainid));
    dispatch(changeToChain(chainid));
    dispatch(
      changeFromToken({
        name: tokenList[0].name,
        adress: tokenList[0].contract_addr,
        image: `https://cdn.akka.finance/images/${chainInfo.apiKey}/${tokenList[0].contract_addr}.png`,
        symbol: tokenList[0].symbol,
        decimals: tokenList[0].decimals,
      })
    );
    dispatch(
      changeToToken({
        name: tokenList[0].name,
        adress: tokenList[0].contract_addr,
        image: `https://cdn.akka.finance/images/${chainInfo.apiKey}/${tokenList[0].contract_addr}.png`,
        symbol: tokenList[0].symbol,
        decimals: tokenList[0].decimals,
      })
    );
  };

  useEffect(() => {
    if (amount === "") {
      dispatch(changeShowRoute(false));
      dispatch(changeResponseData(undefined));
    }
  }, [amount]);

  useEffect(() => {
    if (isActive) {
      if (chainId === walletChainId) {
        if (
          amount
        ) {
          if (oneChainSwapDesc === null) {
            dispatch(
              changeSwapButtonState({
                state: SwapButonStates.LOADING,
                text: "Please wait...",
                isDisable: true,
              })
            );
          }
          else {
            if (
              balance &&
              balance?.gte(parseUnits(amount, fromToken.decimals))
            ) {
              if (responseData?.price_impact <= -10 || responseData?.price_impact >= 10) {
                dispatch(
                  changeSwapButtonState({
                    state: SwapButonStates.SWAP,
                    text: "Price Impact Too High",
                    isDisable: true,
                  })
                );
              }
              else {
                if (
                  currentAllowance &&
                  currentAllowance.gte(parseUnits(amount, fromToken.decimals))
                ) {
                  dispatch(
                    changeSwapButtonState({
                      state: SwapButonStates.SWAP,
                      text: "Swap",
                      isDisable: false,
                    })
                  );
                }
                else {
                  dispatch(
                    changeSwapButtonState({
                      state: SwapButonStates.APPROVE,
                      text: "APPROVE",
                      isDisable: false,
                    })
                  );
                }
              }
            }
            else {
              dispatch(
                changeSwapButtonState({
                  state: SwapButonStates.INSUFFICIENT_BALANCE,
                  text: "Insufficient Balance",
                  isDisable: true,
                })
              );
            }
          }
        }
        else {
          dispatch(
            changeSwapButtonState({
              state: SwapButonStates.ENTER_AMOUNT,
              text: "Enter Amount",
              isDisable: true,
            })
          );
        }
      }
      else {
        dispatch(
          changeSwapButtonState({
            isDisable: false,
            state: SwapButonStates.SWITCH_CHAIN,
            text: "Switch your wallet chain",
          })
        );
      }
    } else {
      dispatch(
        changeSwapButtonState({
          isDisable: false,
          state: SwapButonStates.CONNECT_TO_WALLET,
          text: "Connect Wallet",
        })
      );
    }
  }, [
    isActive,
    amount,
    currentAllowance,
    balance,
    fromToken,
    fromChain,
    chainId,
    walletChainId,
    responseData,
    oneChainSwapDesc,
    counter
  ]);

  // check whether the user has approved the router on the input token
  const [approval,] = useApproveCallbackFromTrade(fromToken.adress);

  useEffect(() => {
    if (approveState === ApprovalState.APPROVED) {
      dispatch(
        changeSwapButtonState({
          state: SwapButonStates.SWAP,
          text: "Swap",
          isDisable: false,
        })
      );
    }
  }, [approveState, amount]);
  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);
  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  async function multiCallSwap() {
    chainId === ChainId.CORE || chainId === ChainId.SHARDEUM ?
      coreMultiPathSwap(
        oneChainSwapDesc.amountIn,
        oneChainSwapDesc.amountOutMin,
        oneChainSwapDesc.data,
        account,
        oneChainSwapDesc.akkaFee?.fee,
        oneChainSwapDesc.akkaFee?.v,
        oneChainSwapDesc.akkaFee?.r,
        oneChainSwapDesc.akkaFee?.s,
        oneChainSwapDesc.value
      ).then(() => {
        toast.success(`Transaction submitted successfully`);
        dispatch(changeApprovevalue(null));
        dispatch(changeAmount(""));
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.ENTER_AMOUNT,
            text: "Enter Amount",
            isDisable: true,
          })
        );
      }).catch((err) => {
        toast.error(`Error happend: ${err?.message}`);
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.REFRESH_REQUEST,
            text: "Quote Expired. Please refresh",
            isDisable: false,
          })
        );
      })
      :
      multiPathSwap(
        oneChainSwapDesc.amountIn,
        oneChainSwapDesc.amountOutMin,
        oneChainSwapDesc.data,
        oneChainSwapDesc.bridge,
        oneChainSwapDesc.dstData,
        account,
        oneChainSwapDesc.stargate_fee ? oneChainSwapDesc.stargate_fee : "0",
        oneChainSwapDesc.value
      ).then(() => {
        toast.success(`Transaction submitted successfully`);
        dispatch(changeApprovevalue(null));
        dispatch(changeAmount(""));
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.ENTER_AMOUNT,
            text: "Enter Amount",
            isDisable: true,
          })
        );
      }).catch((err) => {
        toast.error(`Error happend: ${err?.message}`);
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.REFRESH_REQUEST,
            text: "Quote Expired. Please refresh",
            isDisable: false,
          })
        );
      })
  }
  const handleSwapButtonClick = async () => {
    switch (swapButtonData.state) {
      case SwapButonStates.APPROVE:
        dispatch(ApproveStatus(true))
        break;
      case SwapButonStates.SWAP:
        multiCallSwap();
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.SWAP,
            text: "Please wait ...",
            isDisable: true,
          })
        );
        break;
      case SwapButonStates.CONNECT_TO_WALLET:
        dispatch(connectWalletStatus(true));
        break;
      case SwapButonStates.SWITCH_CHAIN:
        dispatch(
          changeSwapButtonState({
            state: SwapButonStates.LOADING,
            text: "Please wait...",
            isDisable: true,
          })
        );
        changeChainId(chainId);
        break;
      case SwapButonStates.REFRESH_REQUEST:
        dispatch(changeCounter(counter + 1));
        break;
    }
  };

  const themeMode = useSelector(({ theme }) => theme.value);
  const showRoute = useSelector(({ route }) => route.showRoute);

  useEffect(() => {
    getTokenlist(NetworkName.BITGERT).then((res) => {
      localStorage.setItem("bitgertTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.BITGERT} tokenlist`);
      });
    getTokenlist(NetworkName.BSC).then((res) => {
      localStorage.setItem("bscTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.BSC} tokenlist`);
      });
    getTokenlist(NetworkName.FTM).then((res) => {
      localStorage.setItem("fantomTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.FTM} tokenlist`);
      });
    getTokenlist(NetworkName.ETH).then((res) => {
      localStorage.setItem("ethereumTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.ETH} tokenlist`);
      });
    getTokenlist(NetworkName.XDC).then((res) => {
      localStorage.setItem("xdcTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.XDC} tokenlist`);
      });
    getTokenlist(NetworkName.WAN).then((res) => {
      localStorage.setItem("wanTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.WAN} tokenlist`);
      });
    getTokenlist(NetworkName.TELOS).then((res) => {
      localStorage.setItem("telosTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.TELOS} tokenlist`);
      });
    getTokenlist(NetworkName.OKC).then((res) => {
      localStorage.setItem("okcTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.OKC} tokenlist`);
      });
    getTokenlist(NetworkName.CORE).then((res) => {
      localStorage.setItem("coreTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.CORE} tokenlist`);
      });
    getTokenlist(NetworkName.SHARDEUM).then((res) => {
      localStorage.setItem("shardeumTokens", JSON.stringify(res.data));
    })
      .catch(() => {
        toast.error(`Can't load ${NetworkName.CORE} tokenlist`);
      });
  }, []);

  return (
    <MainStyled bgcolor={themeMode === "light" ? "#F5F5FA" : "#22223d"}>
      <div className='container mx-auto px-0 flex flex-col md:pb-[35px] pt-[35px]'>
        <div
          className={`w-[100%] flex flex-col items-center gap-5 pt-[50px] 2xl:flex-row 2xl:items-start 2xl:gap-0 justify-center ${themeMode === "light" && isMobile ? "bg-[#F5F5FA]" : ""
            }`}
        >
          <div
            className={`w-full h-fit lg:w-[450px] px-[24px] relative z-10 ${themeMode === "light" ? "bg-[#FFFFFF]" : "bg-[#1B1A2E]"
              } md:py-[24px] py-[36px] flex flex-col justify-center items-center rounded-[12px]`}
          >
            <div
              className={`flex justify-between w-[100%] pb-3 absolute top-[-40px] px-[24px] md:px-0 ${themeMode === "light" ? "text-[#22223D]" : "text-[#ebebeb]"
                }`}
            >
              <span className='font-clash text-[20px] font-[500]'>Swap</span>
              <div className='flex'>
                {/* <FromAdvanceSettingButton /> */}
                <FromRefresh />
              </div>
            </div>
            <FromBox balance={balance} account={account} />
            <SwitchBox />
            <ToBox />
            <button
              onClick={handleSwapButtonClick}
              className={`py-[16px] w-[100%] h-[56px] text-center font-clash font-[500] text-[18px] text-lg rounded-[12px] ${!swapButtonData.isDisable
                ? `${themeMode === "light"
                  ? "text-white bg-[#6100FF] hover:bg-[#6100FF]/[0.9]"
                  : "text-white bg-[#BE35FF]/[0.45] hover:bg-[#BE35FF]/[0.65]"
                }`
                : `${themeMode === "light"
                  ? "text-[#999898] bg-[#d6d6d6]"
                  : "text-[#717070] bg-[#979797]"
                }`
                }`}
              {...isButtonDisable}
            >
              {swapButtonData.text}
            </button>
            <hr className={`w-full my-3 ${themeMode === "light" ? "opacity-60" : "opacity-10"}`} />
            <div className='flex flex-col items-start justify-between my-1 w-full'>
              <div className='flex justify-start items-center'>
                <span
                  className={`text-[14px] ${themeMode === "light" ? "text-[#525168]" : "text-white"
                    }`}
                >
                  Slippage Telorance
                </span>
              </div>
              <div className='flex justify-between w-[100%] my-[14px]'>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.005 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.005 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.005));
                  }}
                >
                  0.5%
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.01 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.01 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.01));
                  }}
                >
                  1%
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.02 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.02 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.02));
                  }}
                >
                  2%
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.03 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.03 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.03));
                  }}
                >
                  3%
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.04 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.04 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.04));
                  }}
                >
                  4%
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${slippageTolerance === 0.05 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${slippageTolerance === 0.05 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeSlippageTolerance(0.05));
                  }}
                >
                  5%
                </div>
              </div>
              <div className='flex justify-start items-center'>
                <span
                  className={`text-[12px] ${themeMode === "light" ? "text-[#9996B3]" : "text-[#9996B3]"
                    }`}
                >
                  Slippage of higher than 3% is recommended for cross-chain swaps.
                </span>
              </div>
            </div>
            <hr className={`w-full my-3 ${themeMode === "light" ? "opacity-60" : "opacity-10"}`} />
            <div className='flex flex-col items-start justify-between my-1 w-full'>
              <div className='flex justify-start items-center'>
                <span
                  className={`text-[14px] ${themeMode === "light" ? "text-[#525168]" : "text-white"
                    }`}
                >
                  Transaction Speed <span
                    className={`text-[12px] ${themeMode === "light" ? "text-[#9996B3]" : "text-[#9996B3]"
                      }`}
                  >(only for cross-chain swaps)</span>
                </span>
              </div>
              <div className='flex justify-start w-[100%] my-[14px] gap-[8px]'>
                <div
                  className={`${themeMode === "light"
                    ? `${bridgeFee === 500000 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${bridgeFee === 500000 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeBridgeFee(500000));
                  }}
                >
                  Normal
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${bridgeFee === 1000000 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${bridgeFee === 1000000 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeBridgeFee(1000000));
                  }}
                >
                  Fast
                </div>
                <div
                  className={`${themeMode === "light"
                    ? `${bridgeFee === 2000000 ? "bg-[#814AFB] text-white" : "bg-[#F5F5FA] text-[#22223D]"}`
                    : `${bridgeFee === 2000000 ? "bg-[#814AFB] text-white" : "bg-[#22223D] text-white"}`
                    } p-[12px] text-[14px] hover:bg-[#814AFB] hover:text-white cursor-pointer rounded-[12px]`}
                  onClick={() => {
                    dispatch(changeBridgeFee(2000000));
                  }}
                >
                  Super Fast
                </div>
              </div>
              <div className='flex justify-start items-center'>
                <span
                  className={`text-[12px] ${themeMode === "light" ? "text-[#FFC200]" : "text-[#FFC200]"
                    }`}
                >
                  Increasing transaction speed will result in higher gas prices!
                </span>
              </div>
            </div>
          </div>
          {showRoute && route && responseData ? (
            <div className='w-full md:w-auto md:ml-[24px] md:justify-start md:items-start pb-[24px] flex flex-col relative z-10'>
              <div
                className={`w-full md:justify-start lg:w-[1050px] px-[24px] py-[24px] rounded-[12px] flex flex-col justify-center items-start ${themeMode === "light" ? "bg-[#ffffff]" : "bg-[#1B1A2E]"
                  }`}
              >
                <div className='text-left pb-[24px] md:p-[0px] md:pb-[7px]'>
                  <p
                    className={`text-[18px] ${themeMode === "light"
                      ? "text-[#22223d]"
                      : "text-[#EEEEEE]"
                      } font-clash`}
                  >{`${fromToken.name} / ${toToken.name}`}</p>
                  <p
                    className={`text-[30px] ${themeMode === "light"
                      ? "text-[#22223d]"
                      : "text-[#EEEEEE]"
                      } font-['Outfit']`}
                  >
                    {responseData?.return_amount
                      ? responseData.return_amount / Number(amount)
                      : ""}
                  </p>
                </div>
                {isLoadingRoute ? <MyLoader /> : <InfoBox />}
                {isLoadingRoute ? (
                  ""
                ) : (
                  <div
                    className={`text-[20px] font-medium ${themeMode === "light"
                      ? "text-[#22223D]"
                      : "text-[#eeeeee]"
                      } w-[100%] font-clash text-center my-5`}
                  >
                    Order Routing
                  </div>
                )}
                {isLoadingRoute ? "" : <Route />}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </MainStyled>
  );
}

export default Main;
