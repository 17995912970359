import styled from "styled-components";
import ToChangeChainButton from "./ToChangeTokenButton";
import ToChangeNetworkButton from "./ToChangeNetworkButton";
import ToInput from "./ToInput";
import { useSelector } from "react-redux";
import useChainInfo from "../../../hooks/useChainInfo";
import { isMobile } from "react-device-detect";

const StyledToBox = styled.div<{ color: string; backgroundColor: string }>`
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 24px;
  // padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ color }) => (color ? color : "black")};
`;
function ToBox() {
  const themeMode = useSelector(({ theme }) => theme.value);
  const chainId = useSelector(({ route }) => route.toChain);
  const chainInfo = useChainInfo(chainId)
  return (
    <StyledToBox
      color={themeMode === "light" ? "white" : "white"}
      backgroundColor={themeMode === "light" ? "transparent" : "transparent"}
    >
      <div className='pb-[10px] py-1 w-[100%] flex justify-between'>
        <div
          className={`font-clash font-[500] text-[16px] ${themeMode === "light" ? "text-[#22223D]" : "text-[#eeeeee]"
            }`}
        >
          To
        </div>
        {/* <ToBalance /> */}
      </div>
      <div className='flex justify-between flex-col rounded-[5px]'>
        {!isMobile ?
          <div className='w-[100%] flex justify-between items-center gap-[16px]'>
            <ToChangeNetworkButton
              imageSrc={chainInfo.icon}
              coinName={chainInfo.name}
            />
            <ToChangeChainButton
              imageSrc={
                "https://app.1inch.io/assets/images/network-logos/ethereum.svg"
              }
              coinName={"Ethereum"}
              chain={"mainnet"}
            />
            <ToInput />
          </div>
          :
          <>
            <div className='w-[100%] flex justify-between items-center gap-[16px]'>
              <ToChangeNetworkButton
                imageSrc={chainInfo.icon}
                coinName={chainInfo.name}
              />
              <ToChangeChainButton
                imageSrc={
                  "https://app.1inch.io/assets/images/network-logos/ethereum.svg"
                }
                coinName={"Ethereum"}
                chain={"mainnet"}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <ToInput />
            </div>
          </>
        }
      </div>
    </StyledToBox>
  );
}

export default ToBox;
