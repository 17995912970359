import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

function InfoBox() {
  const showRoute = useSelector(({ route }) => route.showRoute);
  const route = useSelector(({ route }) => route);
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <>
      {showRoute ? (
        <div
          className={`flex ${isMobile && "flex-col"
            } justify-between pt-[10px] pb-[10px] p-[15px] my-3 ${themeMode === "light"
              ? "bg-[#F5F5FA] text-[#22223D]"
              : "bg-[#22223D] text-white"
            } w-[100%] font-outfit font-[400] text-[14px] rounded-[8px]`}
        >
          <div
            className={`flex ${themeMode === "light" ? "text-[#22223D]" : "text-white"
              } my-3 ${isMobile ? "w-[100%] justify-between" : "justify-center w-[25%]"
              }`}
          >
            <span
              className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                } mr-3`}
            >
              Input amount in usd
            </span>
            <span>${route.responseData["input_amount_in_usd"].toFixed(2)}</span>
          </div>
          <div
            className={`flex text-white my-3 ${isMobile ? "w-[100%] justify-between" : "justify-center w-[25%]"
              }`}
          >
            <span
              className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                } mr-3`}
            >
              Akka Fee
            </span>
            <span className='text-[#4FC47E]'>$0</span>
          </div>
          {route?.responseSwapData?.["total_gas_fee_in_usd"] !== undefined &&
            <div
              className={`flex text-white my-3 ${isMobile ? "w-[100%] justify-between" : "justify-center w-[25%]"
                }`}
            >
              <span
                className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                  } mr-3`}
              >
                Est. Gas
              </span>
              <span
                className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                  }`}
              >${route?.responseSwapData?.["total_gas_fee_in_usd"].toFixed(2)}</span>
            </div>
          }
          <div
            className={`flex text-white my-3 ${isMobile ? "w-[100%] justify-between" : "justify-center w-[25%]"
              }`}
          >
            <span
              className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                } mr-3`}
            >
              Price Impact
            </span>
            <span
              className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                }`}
            >
              {route.responseData["price_impact"].toFixed(2)}%
            </span>
          </div>
          {(
            route.responseData["return_amount_in_usd"] -
            route.responseData["best_alt"]
          ) > 0 &&
            <div
              className={`flex text-white my-3 ${isMobile ? "w-[100%] justify-between" : "justify-center w-[25%]"
                }`}
            >
              <span
                className={`${themeMode === "light" ? "text-[#22223D]" : "text-[#bfbfbf]"
                  } mr-3`}
              >
                You Save
              </span>
              <span className='text-[#4FC47E]'>
                $
                {(
                  route.responseData["return_amount_in_usd"] -
                  route.responseData["best_alt"]
                ).toFixed(3)}
              </span>
            </div>
          }
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default InfoBox;
