import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

function RouteSplitingPercentage({ percentage }) {
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <div
      className={`z-20 flex justify-center items-center ${
        themeMode === "light"
          ? "bg-[#F5F5FA] text-[#22223D]"
          : "bg-[#22223D] text-white"
      } py-3 ${
        isMobile ? "px-3 mx-0 h-[45px]" : "px-3 mx-2 h-[40px]"
      } text-[16px] rounded-[12px]`}
    >
      <span>{percentage}</span>
    </div>
  );
}

export default RouteSplitingPercentage;
