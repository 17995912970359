import axios from "axios";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import CloseIcon from "../../../assets/img/close.png";
import SearchIcon from "../../../assets/img/search-line.png";
import DarkSearchIcon from "../../../assets/img/darksearch-line.png";
import { changeModalStatus } from "../../../features/modals/modalsSlice";
import FromTokenlistIsLoading from "../From/FromTokenlistIsLoading";
import ToToken from "./ToToken";
import { v4 as uuidv4 } from "uuid";
import { getTokenlist } from "../../../config/api";
import { TokenListApi } from "../../../config/api/types";
import { ChainId, NetworkName } from "../../../config/constants/types";
import useGetTokenList from "../../../hooks/useGetTokenList";
const StyledInput = styled.input<
  HTMLAttributes<HTMLInputElement> & {
    color: string;
  }
>`
  width: 100%;
  height: 48px;
  padding: 15px 15px;
  padding-left: 45px;
  margin-bottom: 30px;
  margin-top: 10px;
  position: relative;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.02);
  border: 1px solid #414141;
  backdrop-filter: blur(30px);
  color: ${({ color }) => color};
  font-size: 20px;

  &:focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-size: 16px;
  }
`;
function ToTokenlist() {
  const chainId = useSelector(({ route }) => route.toChain);
  const tokenList = useGetTokenList(chainId)
  const [tokens, setTokens] = useState<TokenListApi[]>(tokenList);
  const [searchQuery, setSearchQuery] = useState("");
  const [orgListItems, setOrgListItems] = useState<TokenListApi[]>(
    tokenList
  );
  const [listItems, setListItems] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(0);
  // useEffect(() => {
  //   getTokenlist(NetworkName.BITGERT).then((res) => {
  //     setOrgListItems(res.data);
  //     setTokens(res.data);
  //   });
  // }, []);

  useEffect(() => {
    fetchData();
  }, [tokens]);

  const handleScroll = (e) => {
    let myDiv = e.target;
    if (myDiv.offsetHeight + myDiv.scrollTop < myDiv.scrollHeight || isFetching)
      return;
    setIsFetching(true);
  };

  const fetchData = async () => {
    if (orgListItems.length == 0) return;
    const data1 = orgListItems.slice(page * 10, (page + 1) * 10);
    if (page == 0) {
      setListItems(data1);
    } else {
      setListItems([...listItems, ...data1]);
    }
    setPage(page + 1);
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [isFetching]);

  const fetchMoreListItems = () => {
    fetchData();
    setIsFetching(false);
  };

  useEffect(() => {
    if (searchQuery.slice(0, 2) == "0x") {
      setOrgListItems(
        tokens.filter(
          (c) =>
            c.contract_addr.toLowerCase().indexOf(searchQuery.toLowerCase()) !=
            -1
        )
      );
      setPage(0);
      setIsFetching(true);
    } else {
      setOrgListItems(
        tokens.filter(
          (c) => c.symbol.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1
        )
      );
      setPage(0);
      setIsFetching(true);
    }
  }, [searchQuery]);
  const themeMode = useSelector(({ theme }) => theme.value);
  return (
    <>
      {/* Tokens list search box */}
      <div className='relative'>
        <StyledInput
          color={themeMode === "light" ? "#22223D" : "white"}
          className='font-outfit font-[500] text-[16px] rounded-[12px] hover:border-[#ffffff]/[0.2] focus:border-[#D6C1FC]'
          type='text'
          placeholder='Search name or paste address'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <img
          src={themeMode === "light" ? DarkSearchIcon : SearchIcon}
          alt=''
          className='absolute top-[26px] left-[15px] w-[16px]'
        />
      </div>

      {/* Tokens list box */}
      <div
        className='flex flex-col w-[100%] overflow-y-scroll h-[500px] scrollbar'
        onScroll={handleScroll}
      >
        {tokens.length == 0 ? (
          // is Loading Component
          <FromTokenlistIsLoading />
        ) : (
          listItems.map((token, index) => (
            <ToToken token={token} index={index} key={uuidv4()} />
          ))
        )}
      </div>
    </>
  );
}

export default ToTokenlist;
