import { TransactionResponse } from "@ethersproject/providers";
import { BigNumber, BigNumberish, BytesLike } from "ethers";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useWallet from "../components/Wallets/useWallet";
import {
  AkkaRouter,
  AkkaRouterInterface,
  BridgeDescriptionStruct,
  SplitedPathDescriptionStruct,
} from "../config/abi/types/AkkaRouter";
import { useTransactionAdder } from "../state/transactions/hooks";
import { useCallWithoutGasPrice } from "./useCallWithoutGasPrice";
import { useAkkaContract, useAkkaCoreContract } from "./useContract";
import { AkkaRouterCore } from "../config/abi/types";

export function useAkkaBitgertAggrigatorSwapCallback(): {
  multiPathSwap?:
    | undefined
    | ((
        amountIn: BigNumberish,
        amountOutMin: BigNumberish,
        data: SplitedPathDescriptionStruct[],
        bridge: BridgeDescriptionStruct[],
        dstData: SplitedPathDescriptionStruct[],
        to: string,
        bridgeFee: BigNumberish,
        value: string
      ) => Promise<TransactionResponse>);
  coreMultiPathSwap?:
    | undefined
    | ((
        amountIn: BigNumberish,
        amountOutMin: BigNumberish,
        data: SplitedPathDescriptionStruct[],
        to: string,
        fee: string,
        v: string,
        r: string,
        s: string,
        value: string
      ) => Promise<TransactionResponse>);
  getBridgeFee?: (
    amountIn: BigNumberish,
    bridge: BridgeDescriptionStruct[],
    dstData: SplitedPathDescriptionStruct[],
    to: string
  ) => Promise<[BigNumber, BigNumber]>;
  inputError?: string;
} {
  const wallet = useSelector(({ account }) => account.wallet);
  const { useChainId } = useWallet(wallet);
  const chainId = useChainId();
  const { callWithoutGasPrice, callWithGasPrice } = useCallWithoutGasPrice<
    AkkaRouter | AkkaRouterCore,
    TransactionResponse
  >();
  const akkaContract = useAkkaContract();
  const akkaCoreContract = useAkkaCoreContract();
  const addTransaction = useTransactionAdder();
  const inputAmount = useSelector(({ route }) => route.amount);
  return useMemo(() => {
    const sufficientBalance = inputAmount;

    return {
      multiPathSwap: async (
        amountIn,
        amountOutMin,
        data,
        bridge,
        dstData,
        to,
        bridgeFee,
        value
      ) => {
        const tx = await callWithGasPrice(
          akkaContract,
          "multiPathSwap",
          [amountIn, amountOutMin, data, bridge, dstData, to],
          {
            value:
              bridge.length !== 0
                ? BigNumber.from(bridgeFee.toString())
                    .add(BigNumber.from(value.toString()))
                    .toString()
                : value.toString(),
          }
        );

        addTransaction(tx, {
          summary: `swap ${inputAmount}`,
          type: dstData.length === 0 ? "swap" : "multichain-swap",
        });

        return tx as TransactionResponse;
      },
      coreMultiPathSwap: async (
        amountIn,
        amountOutMin,
        data,
        to,
        fee,
        v,
        r,
        s,
        value
      ) => {
        const tx = await callWithGasPrice(
          akkaCoreContract,
          "multiPathSwap",
          [amountIn, amountOutMin, data, to, fee, v, r, s],
          {
            value: value.toString(),
          }
        );

        addTransaction(tx, {
          summary: `swap ${inputAmount}`,
          type: "swap",
        });

        return tx as TransactionResponse;
      },
      inputError: sufficientBalance ? undefined : "Insufficient token balance",
    };
  }, [akkaContract, chainId, inputAmount, addTransaction, callWithoutGasPrice]);
}
