import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import _ from "lodash";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { changeAmount } from "../../../features/route/routeSlice";
import FromAdvanceSettingButton from "./FromAdvanceSettingButton";
import FromChangeNetworkButton from "./FromChangeNetworkButton";
import FromChangeTokenButton from "./FromChangeTokenButton";
import FromInput from "./FromInput";
import FromRefresh from "./FromRefresh";
import useChainInfo from "../../../hooks/useChainInfo";
import useWallet from "../../Wallets/useWallet";
import { RotatingLines } from "react-loader-spinner";
import walletIcon from "../../../assets/wallet.svg";
import { isMobile } from "react-device-detect";
import convertToSixDecimal from "../../../utils/convertToSixDecimal";

// From Box Styles
const StyledFromBox = styled.div<{ color: string; backgroundColor: string }>`
  background: transparent;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;
  width: 100%;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ color }) => (color ? color : "black")};
`;
interface FromBoxProps {
  account: string;
  balance: BigNumber;
}
// From Box Component
const FromBox: FC<FromBoxProps> = ({ account, balance }) => {
  // Get data from redux
  const chainId = useSelector(({ route }) => route.fromChain);
  const themeMode = useSelector(({ theme }) => theme.value);
  const fromToken = useSelector(({ route }) => route.fromToken);
  const chainInfo = useChainInfo(chainId)
  const dispatch = useDispatch();
  const wallet = useSelector(({ account }) => account.wallet);
  const balanceLoader = useSelector(({ account }) => account.balanceLoader);
  const {
    useIsActive,
  } = useWallet(wallet);
  const isActive = useIsActive();
  return (
    <StyledFromBox
      color={themeMode === "light" ? "white" : "white"}
      backgroundColor={themeMode === "light" ? "transparent" : "transparent"}
    >
      {/* box top bar */}
      <div
        className={`py-1 pb-[10px] w-[100%] flex justify-between font-clash font-[500] text-[16px] items-center h-[32px] ${themeMode === "light" ? "text-[#22223D]" : "text-[#eeeeee]"
          }`}
      >
        From
        {isActive ?
          <div className='flex items-center justify-center'>
            <span
              className={`flex items-center py-1 mx-1 rounded-[5px] font-outfit text-[#525168]`}
            >
              <img className="mr-[7px]" src={walletIcon} alt="Wallet Icon" />
              {balance ?
                balanceLoader ? <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="14"
                  visible={true}
                /> : balance.eq(0) ? `0 ${fromToken.symbol}` : `${convertToSixDecimal(formatUnits(balance, fromToken.decimals))} ${fromToken.symbol}`
                : <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="16"
                  visible={true}
                />}
            </span>
          </div> : ""}

      </div>
      {/* box datas */}
      <div className='flex justify-between flex-col rounded-[5px]'>
        {!isMobile ?
          <div className='w-[100%] flex justify-between items-center gap-[16px] mt-[6px]'>
            {/* from network */}
            <FromChangeNetworkButton
              imageSrc={chainInfo.icon}
              coinName={chainInfo.name}
            />
            {/* from token */}
            <FromChangeTokenButton
              imageSrc={
                "https://app.1inch.io/assets/images/network-logos/ethereum.svg"
              }
              coinName={"Ethereum"}
              chain={"mainnet"}
            />

            <FromInput />
            {!balanceLoader && <button
              onClick={() =>
                dispatch(
                  changeAmount(
                    balance
                      ? (
                        convertToSixDecimal(formatUnits(balance, fromToken.decimals))
                      )
                      : "0"
                  )
                )
              }
              className={`font-outfit font-[500] text-[14px] ${themeMode === "light" ? "bg-[#F5F5FA] text-[#22223D]" : "bg-[#22223D] text-[#FFFFFF]"}  pt-[4px] pb-[6px] px-[12px] rounded-[16px] absolute right-0 bottom-[-32px]`}
            >
              Max
            </button>}
          </div>
          :
          <>
            <div className='w-[100%] flex justify-between items-center gap-[16px] mt-[6px]'>
              {/* from network */}
              <FromChangeNetworkButton
                imageSrc={chainInfo.icon}
                coinName={chainInfo.name}
              />
              {/* from token */}
              <FromChangeTokenButton
                imageSrc={
                  "https://app.1inch.io/assets/images/network-logos/ethereum.svg"
                }
                coinName={"Ethereum"}
                chain={"mainnet"}
              />
            </div>
            <div className="flex items-center w-full">
              <FromInput />
              {!balanceLoader && <button
                onClick={() =>
                  dispatch(
                    changeAmount(
                      balance
                        ? (
                          convertToSixDecimal(formatUnits(balance, fromToken.decimals))
                        )
                        : "0"
                    )
                  )
                }
                className={`font-outfit font-[500] text-[14px] ${themeMode === "light" ? "bg-[#F5F5FA] text-[#22223D]" : "bg-[#22223D] text-[#FFFFFF]"}  pt-[4px] pb-[6px] px-[12px] rounded-[16px] absolute right-0 bottom-[-32px]`}
              >
                Max
              </button>}
            </div>
          </>
        }

      </div>
    </StyledFromBox>
  );
};

export default FromBox;
